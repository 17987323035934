type TableBodyProps = React.PropsWithChildren & {
  data: any[];
  dataTestId?: string;
  onClick?: () => void;
};
const TableBodyRow = ({ data, dataTestId, ...rest }: TableBodyProps) => {
  return (
    <div
      data-testid={dataTestId}
      className={`grid custom-grid-cols-${data.length} gap-4 cursor-pointer w-full text-center border-grey-50 border-b last:border-b-0 text-[14px] pt-7 pb-4`}
      {...rest}
    >
      {data.map((d) => {
        return <div>{d}</div>;
      })}
    </div>
  );
};

export default TableBodyRow;
