import { Transaction } from "@nestcoinco/onboard-api-gateway-api-client";
import Table from "modules/common/components/Table";
import TableBodyRow from "modules/common/components/Table/TableBodyRow";
import { enumToReadable } from "modules/common/utils/enumFormatters";
import React from "react";
import { useNavigate } from "react-router";
import dateService from "services/dateService";
import { getValueFromMetadata } from "utils/common";

type TransactionTablePropsType = {
  data: Array<Transaction>;
  setTransaction: (item: Transaction) => void
}

const headers = [
  "Time",
  "Summary",
  "Amount",
  "Status",
  "Rate",
  "Local currency",
  "Local amount",
  "Action"
];

const TransactionTable = ({
  data,
  setTransaction
}: TransactionTablePropsType) => {
  const navigate = useNavigate();
  return (
    <Table headers={headers} dataTestId="transaction-table">
      {data.map((item, index) => {
        const {
          createdAt,
          status,
          amount,
          metadata,
          type
        } = item;

        const rate = getValueFromMetadata("rate", metadata!);
        const localCurrency = getValueFromMetadata("localCurrency", metadata!);

        const transactionData = [
          dateService.timeFromNow(createdAt!),
          enumToReadable(type),
          amount,
          status,
          Number(rate) ? (Number(rate)).toFixed(2) : "-",
          localCurrency || "-",
          Number(rate)  ? (amount * Number(rate)).toFixed(2): "-",
          <p
            className="text-primary-300"
            onClick={() => {
              setTransaction(item);
            }}
          >
            View
          </p>
        ];

        return <TableBodyRow data={transactionData} key={index}/>
      })}
    </Table>
  );
};

export default TransactionTable;
