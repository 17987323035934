import {
  MerchantAccountResponse,
  MerchantAccountStatus,
  CreateAdDto,
  PayoutsSvcProviderPaymentChannel as PayoutSvcProviderPaymentChannel,
  AdBehaviour,
} from "@nestcoinco/onboard-api-gateway-api-client";
import ordersService from "api/ordersService";
import payoutService from "api/payoutService";
import Button, { LoadingButton } from "modules/common/components/Button/Button";
import { CountInput } from "modules/common/components/Input/CountInput";
import Modal from "modules/common/components/Modal/Modal";
import { formatPayoutMethod } from "modules/common/utils/enumFormatters";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import toastNotificationService, {
  ToastTypeEnum,
} from "services/toastNotificationService";
import { BASE_MARGIN } from "./api/constants";
import { calculateFlexibleOfferRate } from "./utils/flexibleOfferRate";
import { Alert } from "modules/common/components/Alert";
import Loader from "modules/common/components/Icons/Loader";

type AdsProps = {
  isEdit: boolean;
  data: any;
};

function CreateAds({ isEdit, data }: AdsProps) {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState<Array<MerchantAccountResponse>>([]);
  const [paymentChannels, setPaymentChannels] = useState<
    Array<PayoutSvcProviderPaymentChannel>
  >([]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [mkRate, setMkRate] = useState<number>(0);
  const [isLoadingRate, setIsLoadingRate] = useState(false);
  const [dailyLimit, setDailyLimit] = useState<number>();
  const [disableDailyLimit, setDisableDailyLimit] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [supportedCurrencies, setSupportedCurrencies] = useState<string[]>();
  const [adData, setAdData] = useState<CreateAdDto>({
    maximumAmount: data?.maximumAmount,
    minimumAmount: data?.minimumAmount,
    paymentAccountId: data?.paymentAccountId || "",
    payoutCurrency: data?.payoutCurrency || "",
    rate: data?.rate || 0,
    behaviour: data?.behaviour || AdBehaviour.FLEXIBLE,
    rateCap: data?.rateCap || undefined,
    flexibleRate: data?.flexibleRate || BASE_MARGIN,
  });

  const [inputError, setInputError] = useState({
    paymentAccountId: { message: "", error: false },
    maximumAmount: { message: "", error: false },
    minimumAmount: { message: "", error: false },
    payoutCurrency: { message: "", error: false },
    rate: { message: "", error: false },
    dailyLimit: { message: "", error: false },
    maximumRate: { message: "", error: false },
    behaviour: { message: "", error: false },
    rateCap: { message: "", error: false },
    flexibleRate: { message: "", error: false },
  });
  const minRateLimit = mkRate ? 0.995 * mkRate : null;

  // set Account
  useEffect(() => {
    payoutService
      .getAccounts({ size: 100 })
      .then((data) => {
        setAccounts(
          data.accounts.filter(
            (account) => account.status !== MerchantAccountStatus.IN_ACTIVE
          )
        );
      })
      .catch((err) => {
        toastNotificationService.alert(
          "Error fetching accounts",
          ToastTypeEnum.ERROR
        );
      });
  }, []);

  useEffect(() => {
    if (!mkRate) return;
    setAdData((state) => ({
      ...state,
      rate:  state.rate || mkRate,
    }));
  }, [mkRate]);

  useEffect(() => {
    if (isEdit && adData.payoutCurrency) {
      const account = accounts.find((account) => {
        return account.accountId === adData.paymentAccountId;
      });
      setSupportedCurrencies(account?.providerDetails?.supportedCurrencies);
      let channels = account?.providerDetails?.supportedPaymentChannels;
      setPaymentChannels(channels!);
    }
  }, [accounts, adData.paymentAccountId, adData.payoutCurrency, isEdit]);

  useEffect(() => {
    if (!adData.payoutCurrency) {
      return;
    }
    setIsLoadingRate(true);
    ordersService
      .getRates(adData.payoutCurrency)
      .then((data) => {
        setMkRate(data.price!);
      })
      .catch((err) => {
        setInputError((inputError) => ({
          ...inputError,
          payoutCurrency: {
            message:
              "Error occured fetching current rate. Refresh to try again",
            error: false,
          },
        }));
      })
      .finally(() => {
        setIsLoadingRate(false);
      });
  }, [adData.payoutCurrency]);

  const handleAccountSelect = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    const value = e.target.value;
    // get account
    const account = accounts.find((account) => {
      return account.accountId === value;
    });

    let channels = account?.providerDetails?.supportedPaymentChannels;

    setSupportedCurrencies(account?.providerDetails?.supportedCurrencies);

    setPaymentChannels(channels!);

    setAdData({
      ...adData,
      paymentAccountId: account?.accountId!,
    });

    setInputError({
      ...inputError,
      paymentAccountId: { message: "", error: false },
      payoutCurrency: { message: "", error: false },
    });
  };

  const handleChange = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    const value = e.target.value;
    const name = e.target.name;
    setAdData({
      ...adData,
      [name]: value,
    });
    setInputError({ ...inputError, [name]: { message: "", error: false } });
  };

  const checkMinMaxValue = () => {
    if (Number(adData.minimumAmount) > Number(adData.maximumAmount)) {
      setInputError({
        ...inputError,
        maximumAmount: {
          message: "Max Value should be greter than or equals to Min Value",
          error: true,
        },
      });
    } else {
      setInputError({
        ...inputError,
        maximumAmount: { message: "", error: false },
      });
    }
  };

  const checkError = () => {
    // min greater than max
    let errorExist = false;
    if (Number(adData.maximumAmount) < Number(adData.minimumAmount)) {
      checkMinMaxValue();
      errorExist = true;
    }

    if (adData.rateCap && adData.rateCap! < adData.rate!) {
      checkMaximumRate();
      errorExist = true;
    }

    let data = { ...adData, dailyLimit: dailyLimit } as { [key: string]: any };

    // if empty fields
    let errorData = {};
    for (let adField in data) {
      if (adField === "rateCap") {
        continue;
      }
      if (adField === "rate" && data?.behaviour === AdBehaviour.FLEXIBLE) {
        continue;
      }
      if (adField === "dailyLimit" && disableDailyLimit) {
        continue;
      } else if (!data[adField]) {
        errorData = {
          ...errorData,
          [adField]: { message: "Field can not be empty", error: true },
        };
        errorExist = true;
      }
    }
    if (errorExist) {
      setInputError({ ...inputError, ...errorData });
    }

    return errorExist;
  };

  const isDisabled = useMemo(() => {
    let disabled = false;
    // if empty fields
    let inputErr = { ...inputError } as {
      [key: string]: { message: string; error: boolean };
    };
    for (let errorField in inputError) {
      if (inputErr[errorField].error) {
        disabled = true;
      }
    }

    return disabled;
  }, [inputError]);

  const handleCreate = () => {
    setSubmitLoading(true);
    const dataToSend = { ...adData };
    if (adData.behaviour === AdBehaviour.FIXED) {
      dataToSend.flexibleRate = undefined;
      dataToSend.rateCap = undefined;
    }
    // get data
    ordersService
      .createAd(dataToSend)
      .then(() => {
        toastNotificationService.alert(
          "Ad created successfully",
          ToastTypeEnum.SUCCESS
        );
        navigate("/ads");
      })
      .catch((err) => {
        toastNotificationService.alert(
          err.message || "Error creating ad, check data",
          ToastTypeEnum.ERROR
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const handleEdit = () => {
    setSubmitLoading(true);
    // get data
    ordersService
      .updateAd(data.id, {
        rate: adData.rate,
        minimumAmount: adData.minimumAmount,
        maximumAmount: adData.maximumAmount,
        flexibleRate: adData.flexibleRate,
        behaviour: adData.behaviour,
        rateCap: adData.rateCap,
      })
      .then(() => {
        toastNotificationService.alert(
          "Ad updated successfully",
          ToastTypeEnum.SUCCESS
        );
        navigate("/ads");
      })
      .catch((err) => {
        toastNotificationService.alert(
          err.message || "Error updating ad, check data",
          ToastTypeEnum.ERROR
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  function checkRateLimit(rate: string) {
    if (minRateLimit && Number(rate) < minRateLimit) {
      setShowWarning(true);
      return;
    }
    setShowWarning(false);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateRate = (margin: number) => {
    const adRate = (
      calculateFlexibleOfferRate(mkRate || 0, margin) - BASE_MARGIN
    ).toFixed(2);
    setAdData({
      ...adData,
      rate: Number(adRate),
      flexibleRate: margin,
    });
  };

  const handleMarginChange = (e: any) => {
    const value = e.target.value;
    if (Number(value) >= 0) {
      updateRate(value);
    }
  };

  const handleIncrease = () => {
    updateRate(adData.flexibleRate! + 0.5);
  };

  const handleDecrease = () => {
    updateRate(adData.flexibleRate! - 0.5);
  };

  const checkMaximumRate = () => {
    if (adData.rateCap && adData.rateCap! < adData.rate!) {
      setInputError((inputError) => ({
        ...inputError,
        rateCap: {
          message: "Maximum rate should not be less than your specified rate",
          error: true,
        },
      }));
    }
  };

  const handleSubmit = () => {
    const errorExist = checkError();
    if (errorExist) {
      return;
    }
    // check rate of set
    if (adData.rate! < minRateLimit!) {
      return setOpenModal(true);
    }

    (isEdit ? handleEdit : handleCreate)();
  };

  return (
    <>
      <h1 className="font-semibold text-xl mb-8">
        {isEdit ? "Edit" : "Create"} Ad
      </h1>
      <div className="mb-6 p-4 px-6 rounded-md bg-white flex flex-col text-[16px]">
        <div className="max-w-[923px] mb-6 mt-6">
          <div className="grid md:grid-cols-3 mt-4 gap-y-8 md:gap-x-10 md:gap-y-11 mb-8 md:mb-[100px]">
            <div>
              <p className="font-semibold mb-2">Account</p>
              {accounts?.length ? (
                <select
                  name="account"
                  className="rounded-lg h-[56px] text-left w-full p-3 border border-gray-300 appearance-none bg-[url('./assets/svgs/caret-down.svg')] bg-no-repeat bg-[95%_50%]"
                  placeholder="Select Account"
                  disabled={isEdit}
                  onChange={handleAccountSelect}
                  value={adData.paymentAccountId}
                  data-testid="select-account"
                >
                  {[
                    <option key={0}>Select Account</option>,
                    ...(accounts
                      ? accounts.map((account) => {
                          return (
                            <option
                              key={account.accountId}
                              value={account.accountId}
                            >
                              {`${account.providerDetails?.name} - ${account.accountName}`}
                            </option>
                          );
                        })
                      : []),
                  ]}
                </select>
              ) : (
                <p
                  className="rounded-lg text-left p-3 bg-gray-100 cursor-pointer"
                  onClick={() => navigate("/accounts/connect")}
                >
                  Connect account
                </p>
              )}
              {inputError.paymentAccountId.error ? (
                <p
                  className="text-xs text-error"
                  data-testid="select-account-error"
                >
                  {inputError.paymentAccountId.message}
                </p>
              ) : null}
            </div>
            <div>
              <p className="font-semibold mb-2">Currency</p>
              {supportedCurrencies?.length ? (
                <select
                  name="payoutCurrency"
                  className="rounded-lg h-[56px] text-left p-3 border w-full border-gray-300 appearance-none
                  bg-[url('./assets/svgs/caret-down.svg')] bg-no-repeat bg-[95%_50%]"
                  placeholder="Select Currency"
                  disabled={isEdit}
                  onChange={handleChange}
                  value={adData.payoutCurrency}
                  data-testid="select-account-currency"
                >
                  {[
                    !adData.payoutCurrency ? (
                      <option key={0} value="">
                        Select Currency
                      </option>
                    ) : null,
                    ...(supportedCurrencies
                      ? supportedCurrencies.map((currency) => {
                          return (
                            <option key={currency} value={currency}>
                              {currency}
                            </option>
                          );
                        })
                      : []),
                  ]}
                </select>
              ) : (
                <p className="rounded-lg text-left p-3 bg-gray-100 cursor-not-allowed h-[56px] flex items-center">
                  No account selected
                </p>
              )}
              {inputError.payoutCurrency.error ? (
                <p
                  className="text-xs text-error"
                  data-testid="select-account-currency-error"
                >
                  {inputError.payoutCurrency.message}
                </p>
              ) : null}
            </div>
            <div className="hidden md:block"></div>
            {isLoadingRate ? (
              <div className="col-span-full flex justify-center">
                <Loader stroke="black" />
              </div>
            ) : null}
            {mkRate ? (
              <>
                <div>
                  <p className="font-semibold mb-2">Rate Type</p>
                  <div className="flex items-center  text-left max-sm:w-full bg-white">
                    <div className="inline-block w-full">
                      <div className="w-full">
                        <select
                          name="behaviour"
                          className="h-[56px] rounded-lg text-left p-3 border border-gray-300 w-full appearance-none
                  bg-[url('./assets/svgs/caret-down.svg')] bg-no-repeat bg-[95%_50%]"
                          placeholder="Rate type"
                          disabled={!mkRate}
                          onChange={handleChange}
                          value={adData.behaviour}
                          data-testid="select-account-currency"
                        >
                          <option value={AdBehaviour.FIXED}>Fixed price</option>
                          <option value={AdBehaviour.FLEXIBLE}>
                            Flexible price
                          </option>
                        </select>
                      </div>
                      <Alert
                        content={
                          adData.behaviour === AdBehaviour.FIXED
                            ? "Your rate is fixed and will not change based on the current market rate"
                            : "Your rate will change based on the current market rate."
                        }
                        severity="info"
                        rounded
                        variant="light"
                        className="w-full mt-3"
                        contentClass="!text-12"
                      />

                      {inputError.behaviour.error ? (
                        <p
                          className="text-xs text-error"
                          data-testid="rate-input-error"
                        >
                          {inputError.behaviour.message}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

                {adData.behaviour === AdBehaviour.FIXED ? (
                  <div className="flex flex-col sm:basis-1/2">
                    <p className="font-semibold inline-block text-[16px] mb-2">
                      Your rate
                    </p>
                    <div className="flex items-center  text-left max-sm:w-full bg-white">
                      <div className="inline-block w-full">
                        <div className="w-full flex border border-gray-300 rounded-lg">
                          <div
                            className="p-3 h-[56px] rounded-l-md bg-gray-100 bd-gray-100 min-w-[50px] "
                            data-testid="account-currency"
                          >
                            {adData.payoutCurrency}
                          </div>
                          <div className="w-full">
                            <input
                              className="rounded-r-md h-[56px] p-3 bd-gray-100 focus:outline-none  w-full"
                              onChange={(e) => {
                                handleChange(e);
                                checkRateLimit(e.target.value);
                              }}
                              name="rate"
                              value={adData.rate}
                              type="number"
                              min={1}
                              data-testid="rate-input"
                            />
                          </div>
                        </div>
                        {showWarning ? (
                          <Alert
                            content="Your rate is lower than the market rate, you might not get orders."
                            severity="warning"
                            rounded
                            variant="light"
                            className="w-full mt-3"
                            contentClass="!text-12 !text-[#00050f]"
                          />
                        ) : null}
                        {inputError.rate.error ? (
                          <p
                            className="text-xs text-error"
                            data-testid="rate-input-error"
                          >
                            {inputError.rate.message}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h4 className="font-semibold mb-2">Set margin</h4>
                    <CountInput
                      name="offerMargin"
                      onChange={handleMarginChange}
                      value={adData.flexibleRate}
                      type="number"
                      inputMode="decimal"
                      onDecrease={handleDecrease}
                      onIncrease={handleIncrease}
                      error={false}
                    />
                    <Alert
                      content="Your rate is calculated based on this margin"
                      severity="info"
                      rounded
                      variant="light"
                      className="w-full mt-3"
                      contentClass="!text-12 !text-[#00050f]"
                    />
                  </div>
                )}
                <div>
                  <p className="font-semibold mb-2">Your rate</p>
                  <p className="font-semibold text-24 mb-3.5">{adData.rate}</p>
                  <span className=" text-gray-400">
                    <p className="text-12 text-gray-600 font-medium">
                      Current market rate is{" "}
                      <span className="text-primary-500 font-semibold underline underline-offset-2">
                        {adData.payoutCurrency}
                        {mkRate}
                      </span>
                    </p>
                  </span>
                </div>
                {adData.behaviour === AdBehaviour.FLEXIBLE && (
                  <div>
                    <p className="font-semibold mb-2">Maximum Rate</p>
                    <input
                      className="rounded-lg text-left p-3 border border-gray-300 w-full h-[56px]"
                      onChange={handleChange}
                      name="rateCap"
                      value={adData.rateCap || ""}
                      onBlur={checkMaximumRate}
                      type="number"
                      min={adData.rate}
                      placeholder={`Maximum rate ${
                        adData.payoutCurrency && `(${adData.payoutCurrency})`
                      }`}
                      data-testid="min-order-limit"
                    />
                    {inputError.rateCap.error ? (
                      <p
                        className="text-xs text-error"
                        data-testid="min-order-limit-error"
                      >
                        {inputError.rateCap.message}
                      </p>
                    ) : null}
                    <Alert
                      content="The maximum rate you're willing to buy at"
                      severity="info"
                      rounded
                      variant="light"
                      className="w-full mt-3"
                      contentClass="!text-12"
                    />
                  </div>
                )}
              </>
            ) : null}
          </div>
          {mkRate ? (
            <>
              <div className="max-w-[600px]">
                <p className="font-semibold inline-block text-[16px] mb-4">
                  Order limit{" "}
                  {adData.payoutCurrency && `(${adData.payoutCurrency})`}
                </p>
                <div className="md:grid md:grid-cols-2 md:gap-x-10 mb-6 w-full">
                  <div>
                    <input
                      className="rounded-lg text-left p-3 border border-gray-300 w-full h-[56px]"
                      onChange={handleChange}
                      name="minimumAmount"
                      value={adData.minimumAmount}
                      onBlur={checkMinMaxValue}
                      type="number"
                      min={1}
                      placeholder={`Min ${
                        adData.payoutCurrency && `(${adData.payoutCurrency})`
                      }`}
                      data-testid="min-order-limit"
                    />
                    {inputError.minimumAmount.error ? (
                      <p
                        className="text-xs text-error"
                        data-testid="min-order-limit-error"
                      >
                        {inputError.minimumAmount.message}
                      </p>
                    ) : null}
                  </div>
                  <div>
                    <input
                      className="rounded-lg text-left p-3 border border-gray-300 w-full h-[56px]"
                      onChange={handleChange}
                      name="maximumAmount"
                      value={adData.maximumAmount}
                      onBlur={checkMinMaxValue}
                      type="number"
                      min={1}
                      placeholder={`Max ${
                        adData.payoutCurrency && `(${adData.payoutCurrency})`
                      }`}
                      data-testid="max-order-limit"
                    />
                    {inputError.maximumAmount.error ? (
                      <p
                        className="text-xs text-error"
                        data-testid="max-order-limit-error"
                      >
                        {inputError.maximumAmount.message}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="mb-14 w-full max-w[600px]">
                <p className="py-3 mb-6 font-semibold">
                  Payment channels available:{" "}
                </p>
                <div className="flex gap-y-4">
                  {paymentChannels &&
                    formatPayoutMethod(paymentChannels)?.map((channel) => {
                      return (
                        <span
                          key={channel}
                          className="rounded-md text-center p-2 border border-gray-300 mb-4"
                        >
                          {channel}
                        </span>
                      );
                    })}
                </div>
              </div>
            </>
          ) : null}

          <div className="flex mb-6 w-full gap-x-7 flex-wrap">
            <Button
              className="w-[200px] h-[56px] py-2 text-primary-300 px-6 border border-primary-300"
              onClick={() => navigate("/ads")}
              dataTestId="cancel-create-ad"
            >
              Cancel
            </Button>
            <LoadingButton
              className="w-[200px] h-[56] btn btn-primary py-2 px-6 rounded-[8px] border-primary-300"
              onClick={handleSubmit}
              disabled={isDisabled}
              loading={submitLoading}
              dataTestId="create-edit-ad"
            >
              {isEdit ? "Update" : "Create"}
            </LoadingButton>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openModal}
        closeModal={handleCloseModal}
        title={isEdit ? "Edit Ad" : "Create Ad"}
      >
        <div className="py-5 flex justify-center items-center">
          Your rate is below the average market rate. Do you want to continue?
        </div>
        <div className="flex justify-evenly">
          <Button
            className="border text-error p-2 w-20"
            onClick={handleCloseModal}
            dataTestId="decline-create-ad-interrupt"
          >
            No
          </Button>
          <LoadingButton
            loading={submitLoading}
            className="border text-primary p-2 w-20"
            onClick={isEdit ? handleEdit : handleCreate}
            dataTestId="accept-create-ad-interrupt"
          >
            Yes
          </LoadingButton>
        </div>
      </Modal>
    </>
  );
}

export default CreateAds;
