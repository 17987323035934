import { withdrawlDetailsSchema } from "modules/common/api/formSchema";
import { LoadingButton } from "modules/common/components/Button/Button";
import useCustomForm from "modules/common/hooks/useCustomForm";
import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import {
  externalAddressState,
  withdrawalDetails,
} from "modules/withdrawal/api/externalAddressAtoms";
import { FieldValues, useWatch } from "react-hook-form";
import Input from "modules/common/components/Input/Input";
import walletService from "api/walletService";
import { getPercentageValue, validateAssetAddress } from "modules/common/utils";
import { useQuery } from "react-query";

const CompleteWithdrawal = () => {
  const navigate = useNavigate();
  const [savedWithdrawalDetails, setWithdrawalDetails] = useAtom(withdrawalDetails);
  const { token, network } = useAtom(externalAddressState)[0];

  if (!token || !network) {
    navigate("/withdrawal");
  }

  const { data: wallets } = useQuery("getWallets", () =>
    walletService.getWallets()
  );
  const { balance } = wallets?.[0] || {};

  const { networkInfo, withdrawalFee } = network || {};
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors, isValid },
    setFocus,
  } = useCustomForm(withdrawlDetailsSchema);

  const amountEntered = useWatch({ name: "amount", control });

  function isWithdrawalDetailsValid({ amount, walletAddress }: {
    amount: number;
    walletAddress: string;
  }) {
    const isAmountValid = amount <= balance?.availableBalance!;
    const isAddressValid = validateAssetAddress(token?.name, walletAddress);
    if (!isAmountValid) {
      setError("amount", {
        type: "custom",
        message: "Amount entered should not exceed available balance",
      });
    }
    if (!isAddressValid) {
      setError("walletAddress", {
        type: "custom",
        message: "Please enter a valid wallet address",
      });
    }
    return isAmountValid && isAddressValid;
  }

  async function submitForm({ amount, walletAddress, memo = "" }: FieldValues) {
    if (!isWithdrawalDetailsValid({ amount, walletAddress })) {
      return;
    }
    setWithdrawalDetails({ amount, walletAddress, memo });
    await walletService.authorizeTransaction();
    navigate("/withdrawal/confirm");
  }

  useEffect(() => {
    setFocus("walletAddress");
    if(savedWithdrawalDetails) {
      setValue("amount", savedWithdrawalDetails.amount);
      setValue("walletAddress", savedWithdrawalDetails.walletAddress);
      if(networkInfo && networkInfo.supportsMemo){
        setValue("memo", savedWithdrawalDetails.memo);
      }
    }
  }, [setFocus, setValue, networkInfo, savedWithdrawalDetails]);

  return (
    <div className="mx-auto w-[550px] mt-14 bg-white p-10 rounded-[10px]">
      <h1 className="text-center font-semibold pb-3">Confirm withdrawal</h1>
      <p className="text-center text-sm mb-6">
        Confirm that destination supports{" "}
        <span className="font-semibold">{token?.name}</span> on{" "}
        <span className="font-semibold">
          {networkInfo?.name} ({networkInfo?.alias})
        </span>
        . Withdrawals are final.
      </p>
      <form onSubmit={handleSubmit(submitForm)} className="w-full">
        <Input
          type="text"
          label="walletAddress"
          labelText="Enter Wallet Address"
          placeholder="Enter Wallet Address"
          register={register}
          errors={errors}
          dataTestId="wallet-address"
          errorId="input-error"
        />
        <div className="p-3"/>
        <Input
          label="amount"
          labelText="Enter Amount"
          placeholder="Enter amount"
          register={register}
          errors={errors}
          dataTestId="amount"
          errorId="input-error"
        />
        <div className="p-3"/>
        {networkInfo?.supportsMemo ? (
          <Input
            label="memo"
            placeholder="Enter memo"
            labelText="Enter Memo(optional)"
            register={register}
            errors={errors}
            dataTestId="memo"
          />
        ) : null}
        <div className="flex justify-end gap-4 text-sm py-4">
          <span
            className="cursor-pointer"
            data-testid="10%"
            onClick={() =>
              setValue(
                "amount",
                getPercentageValue(0.1, balance?.availableBalance!),
                { shouldValidate: false }
              )
            }
          >
            [10%]
          </span>{" "}
          <span
            className="cursor-pointer"
            data-testid="25%"
            onClick={() =>
              setValue(
                "amount",
                getPercentageValue(0.25, balance?.availableBalance!),
                { shouldValidate: false }
              )
            }
          >
            [25%]
          </span>{" "}
          <span
            className="cursor-pointer"
            data-testid="50%"
            onClick={() =>
              setValue(
                "amount",
                getPercentageValue(0.5, balance?.availableBalance!),
                { shouldValidate: false }
              )
            }
          >
            [50%]
          </span>
          <span
            data-testid="max"
            className="cursor-pointer"
            onClick={() =>
              setValue("amount", balance?.availableBalance, {
                shouldValidate: true,
              })
            }
          >
            . [MAX]
          </span>
        </div>
        {amountEntered <= balance?.availableBalance! && (
          <p className="pt-2 pb-4">
            Fee = {withdrawalFee} {token?.name}
            {amountEntered > withdrawalFee! && (
              <span>
                , recipient will receive{" "}
                <span className="font-semibold">
                  {amountEntered - withdrawalFee!} {token?.name}
                </span>
              </span>
            )}
          </p>
        )}
        <LoadingButton
          dataTestId="proceed-button"
          disabled={!isValid}
          loading={false}
          className="w-full btn-primary"
        >
          Proceed
        </LoadingButton>
      </form>
    </div>
  );
};

export default CompleteWithdrawal;
