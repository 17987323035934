import {
  TransactionsList,
  TransactionStatus,
  TransactionType,
  Transaction as TransactionDto,
  OpnwalletSvcTransactionStatus,
  OpnwalletSvcTransactionType,
} from "@nestcoinco/onboard-api-gateway-api-client";
import walletService from "api/walletService";
import Button from "modules/common/components/Button/Button";
import WalletCardsContainer from "modules/common/components/Cards/WalletCardsContainer";
import DropDown from "modules/common/components/Dropdown/DropDown";
import ChevronDown from "modules/common/components/Icons/ChevronDown";
import Pagination from "modules/common/components/Pagination/Pagination";
import Transaction from "modules/transaction/Transaction";
import TransactionTable from "modules/transaction/TransactionTable";
import { useEffect, useState } from "react";
import toastNotificationService, {
  ToastTypeEnum,
} from "services/toastNotificationService";

const transactionTypeMap = {
  [OpnwalletSvcTransactionType.CRYPTO_WITHDRAWAL]: "Withdrawl",
  [OpnwalletSvcTransactionType.INTERNAL_CREDIT]: "Credit",
  [OpnwalletSvcTransactionType.INTERNAL_DEBIT]: "Debit",
};

const transactionStatusMap = {
  [OpnwalletSvcTransactionStatus.FAILED]: "Failed",
  [OpnwalletSvcTransactionStatus.IN_PROGRESS]: "Ongoing",
  [OpnwalletSvcTransactionStatus.SUCCESS]: "Completed",
  [OpnwalletSvcTransactionStatus.PENDING]: "Pending",
};

const TransactionHistory = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [typeFilter, setTypeFilter] =
    useState<OpnwalletSvcTransactionType | null>();
  const [statusFilter, setStatusFilter] =
    useState<OpnwalletSvcTransactionStatus | null>();
  const [transactionData, setTransactionData] = useState<TransactionDto>();
  const [transactions, setTransactions] = useState<TransactionsList>({
    name: "",
    records: [],
    nextPage: 1,
    previousPage: undefined,
    size: 10,
    totalItems: 0,
  });

  useEffect(() => {
    const status = statusFilter ? [statusFilter] : undefined;
    const type = typeFilter ? [typeFilter] : undefined;

    walletService
      .getTransactions(status as any, type, currentPage, pageSize)
      .then((data) => {
        setTransactions(data);
      })
      .catch((error) => {
        toastNotificationService.alert(
          "Error fetching transactions",
          ToastTypeEnum.ERROR
        );
      });
  }, [statusFilter, typeFilter, currentPage, pageSize]);

  return transactionData ? (
    <Transaction
      transaction={transactionData}
      setTransaction={setTransactionData}
    />
  ) : (
    <div>
      <section className="bg-gray-white rounded-[10px] p-6 py-10 mb-16">
        <WalletCardsContainer />
      </section>

      <div className="flex items-baseline gap-4 mb-6 p-4 px-6 rounded-md bg-white">
        <p className="mr-auto">Transaction History</p>
        <DropDown
          items={[
            {
              label: "All",
              onClick: () => {
                setTypeFilter(null);
              },
            },
            {
              onClick: () => {
                setTypeFilter(OpnwalletSvcTransactionType.CRYPTO_WITHDRAWAL);
              },
              label:
                transactionTypeMap[
                  OpnwalletSvcTransactionType.CRYPTO_WITHDRAWAL
                ],
            },
            {
              onClick: () => {
                setTypeFilter(OpnwalletSvcTransactionType.INTERNAL_CREDIT);
              },
              label:
                transactionTypeMap[OpnwalletSvcTransactionType.INTERNAL_CREDIT],
            },
            {
              onClick: () => {
                setTypeFilter(OpnwalletSvcTransactionType.INTERNAL_DEBIT);
              },
              label:
                transactionTypeMap[OpnwalletSvcTransactionType.INTERNAL_DEBIT],
            },
          ]}
          placeholder={
            <Button className="flex justify-between border border-gray-300 py-2 w-[120px] font-normal">
              {typeFilter ? transactionTypeMap[typeFilter] : "Type"}{" "}
              <ChevronDown />
            </Button>
          }
        />
        <DropDown
          items={[
            {
              label: "All",
              onClick: () => {
                setStatusFilter(null);
              },
            },
            {
              label: transactionStatusMap[OpnwalletSvcTransactionStatus.FAILED],
              onClick: () => {
                setStatusFilter(OpnwalletSvcTransactionStatus.FAILED);
              },
            },
            {
              label:
                transactionStatusMap[OpnwalletSvcTransactionStatus.SUCCESS],
              onClick: () => {
                setStatusFilter(OpnwalletSvcTransactionStatus.SUCCESS as any);
              },
            },
            {
              label:
                transactionStatusMap[OpnwalletSvcTransactionStatus.IN_PROGRESS],
              onClick: () => {
                setStatusFilter(OpnwalletSvcTransactionStatus.IN_PROGRESS);
              },
            },
          ]}
          placeholder={
            <Button className="flex justify-between border border-gray-300 py-2 w-[120px] font-normal">
              {statusFilter ? transactionStatusMap[statusFilter] : "Status"}{" "}
              <ChevronDown />
            </Button>
          }
        />
      </div>
      <div className="mb-6 p-4 px-6 rounded-md bg-white flex">
        <TransactionTable
          data={transactions.records}
          setTransaction={setTransactionData}
        />
      </div>

      <div className="py-6">
        <Pagination
          size={pageSize}
          totalItems={transactions.totalItems}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default TransactionHistory;
