import {
  OpnwalletSvcTokenDetails,
  OpnwalletSvcTokenNetwork
} from "@nestcoinco/onboard-api-gateway-api-client";
import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const selectedToken = atomWithStorage<TokenDetailsType | null>(
  "selectedToken",
  null
);

export const selectedAsset = atomWithStorage<string | null>(
  "selectedAsset",
  null
);
export const selectedNetwork = atomWithStorage<OpnwalletSvcTokenNetwork | any>(
  "selectedNetwork",
  null
);

export const withdrawalDetails = atomWithStorage("withdrawalDetails", {
  amount: 0,
  walletAddress: "",
  memo: "",
});

export const externalAddressState = atom<
  ExternalAddressProps,
  ExternalAddressProps | null
>(
  (get: any) => ({
    asset: get(selectedAsset) || '',
    token: get(selectedToken)!,
    network: get(selectedNetwork)!,
    amount: get(withdrawalDetails).amount,
    walletAddress: get(withdrawalDetails).walletAddress,
    memo: get(withdrawalDetails).memo,
  }),
  (_get: any, set: any, value: any) => {
    set(externalAddressState, value);
  }
);

interface ExternalAddressProps {
  asset: string;
  token: TokenDetailsType;
  network: OpnwalletSvcTokenNetwork;
  amount: number;
  walletAddress: string;
  memo?: string;
}

interface TokenDetailsType
  extends Omit<OpnwalletSvcTokenDetails, "supportedNetworks" | "symbol" | "logoUrl"> {
  networks: OpnwalletSvcTokenNetwork[];
}
