import { ReactNode } from "react";

type NoTableDataPropsType = {
  message?: string;
  children?: ReactNode;
  dataTestId?: string;
};
const NoTableData = ({ message, children, dataTestId }: NoTableDataPropsType) => {
  return (
    <div className="grid place-items-center p-2 text-center" data-testid={dataTestId}>
      {children ? children : message || "No data"}
    </div>
  );
};

export default NoTableData;
