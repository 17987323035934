import { Tab } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment } from "react";

type TabItemsObject = Record<string, any>;

interface TabProps {
  tabItems: TabItemsObject[];
  defaultIndex?: number;
  onChange?: () => void;
  className?: string;
  dataTestId?: string;
}

const CustomTab = ({
  tabItems,
  defaultIndex = 0,
  onChange = () => {},
  className,
  dataTestId,
}: TabProps) => {
  return (
    <Tab.Group defaultIndex={defaultIndex} onChange={onChange}>
      <Tab.List data-testid={dataTestId} className={className}>
        {tabItems.map((tab) => {
          return (
            <Tab as={Fragment} key={tab.key}>
              {({ selected }: any) => (
                <button
                  data-testid={`tab-item-${tab.key
                    .toLowerCase()
                    .replace(" ", "-")}`}
                  className={classNames(
                    "p-3 pb-2 text-sm focus:outline-dotted focus:outline-primary-300",
                    [
                      selected
                        ? " border-b-[1px] border-b-black font-semibold"
                        : "text-gray ",
                    ]
                  )}
                >
                  {tab.key}
                </button>
              )}
            </Tab>
          );
        })}
      </Tab.List>
      <Tab.Panels>
        {tabItems.map((tab) => {
          return (
            <Tab.Panel className="min-h-[260px]" as="div" key={tab.key}>
              {tab.content}
            </Tab.Panel>
          );
        })}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default CustomTab;
