/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";

export const emailSchema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .trim()
    .max(6)
    .required("otp cannot be empty"),
});

export const otpSchema = yup.object({
  otp: yup.string()
    .min(6)
    .matches(/^[0-9]+$/, "Must be only digits")
    .trim().max(6).required("Please enter your OTP"),
});

export const withdrawlDetailsSchema = yup.object({
  amount: yup.string().trim().required("Amount should be greater than 0"),
  walletAddress: yup
    .string()
    .trim()
    .required("please enter the target's wallet address"),
  memo: yup.string().trim(),
});
