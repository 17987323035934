import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CreateAds from "./CreateAds"

const EditAd = () => {
  const { adId } = useParams();
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    // get ads
    if (!adId || !location?.state?.ad) {
      return navigate('/ads');
    }
  }, [adId,location?.state?.ad, navigate])


  return <CreateAds isEdit={true} data={location?.state?.ad} />
}

export default EditAd;