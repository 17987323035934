import { routes } from "config/routes";
import { convertDateToWords } from "modules/common/utils/dateFormatter";
import { MerchantAccountResponse } from "@nestcoinco/onboard-api-gateway-api-client";
import Table from "modules/common/components/Table";
import React from "react";
import { useNavigate } from "react-router";
import { useQuery } from "react-query";
import ordersService from "api/ordersService";
import { calculateConnectedAds } from "./api/calculateConnectedAds";
import { formatPayoutMethod } from "modules/common/utils/enumFormatters";
import TableBodyRow from "modules/common/components/Table/TableBodyRow";
import toastNotificationService, {
  ToastTypeEnum,
} from "services/toastNotificationService";
import NoTableData from "modules/common/components/Table/NoTableData";
import { NavLink } from "react-router-dom";

const headers = [
  "Set-up Date",
  "Last Used",
  "Service Providers",
  "Balance(s)",
  "Status",
  "Channels",
  "Account Name",
  "Conected Ads",
  "Action",
];

const PaymentAccountsTable = ({
  accounts,
}: {
  accounts: MerchantAccountResponse[] | undefined;
}) => {
  const navigate = useNavigate();
  const { data, error } = useQuery("getAds", () => ordersService.getAds());

  if (error) {
    toastNotificationService.alert("Error fetching Ads", ToastTypeEnum.ERROR);
  }

  return (
    <Table
      className="!w-[76.5rem]"
      headers={headers}
      dataTestId={"account-data-table"}
    >
      {accounts?.length ? (
        accounts?.map((account, index: number) => {
          const {
            createdAt,
            updatedAt,
            providerDetails,
            accountBalances,
            status,
            accountName,
            accountId,
          } = account;
          const connectedAdsCount = calculateConnectedAds(
            data?.ads || [],
            accountId!
          );

          const rowData = [
            convertDateToWords(createdAt),
            convertDateToWords(updatedAt),
            providerDetails?.name,
            accountBalances?.map((acctBalance: any) => {
              if (status !== "ACTIVE") return "-";
              return `${acctBalance.asset} ${acctBalance.amount}`;
            }),
            ((status: string) => {
              const active = status === "ACTIVE";
              return (
                <p className={`text-${active ? "green-700" : "error"}`}>
                  {active ? "Active" : "Inactive"}
                </p>
              );
            })(status),
            <p className="text-[11px]">
              {formatPayoutMethod(
                providerDetails?.supportedPaymentChannels
              )?.map((method) => {
                return (
                  <span className="border px-1 py-px rounded-md">{method}</span>
                );
              })}
            </p>,
            <p className="w-[120px] text-ellipsis overflow-hidden">
              {accountName}
            </p>,
            connectedAdsCount,
            <p
              className="text-primary-300 cursor-pointer"
              data-testid={`view-account-${index}`}
              onClick={() => {
                navigate(`${routes.accounts.index}/${accountId}`, {
                  state: account,
                });
              }}
            >
              View
            </p>,
          ];
          return (
            <TableBodyRow
              dataTestId={`table-row-${index + 1}`}
              data={rowData}
              key={index}
            />
          );
        })
      ) : (
        <NoTableData dataTestId={"no-account-table"}>
          <p>You have no connected accounts</p>
          <NavLink
            className="font-semibold text-sm bg-primary-200 text-primary py-2 px-3 rounded-[10px]"
            to={routes.accounts.connect}
          >
            Connect Account
          </NavLink>
        </NoTableData>
      )}
    </Table>
  );
};

export default PaymentAccountsTable;
