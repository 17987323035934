import React, { FC, InputHTMLAttributes, ReactNode, useRef } from "react";
import cn from "classnames";
import { RemoveIcon } from "../Icons/RemoveIcon";
import { PlusIcon } from "../Icons/PlusIcon";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  tag?: string | ReactNode;
  error?: boolean;
  disabled?: boolean;
  scale?: "lg" | "sm";
  floatingLabel?: boolean;
  className?: string;
  onIncrease: VoidFunction;
  onDecrease: VoidFunction;
}

export const CountInput: FC<InputProps> = ({
  name,
  label,
  value,
  error,
  disabled,
  tag,
  onBlur,
  scale,
  floatingLabel = true,
  className,
  onChange,
  onIncrease,
  onDecrease,
  ...restProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={cn("input-group", className, {
        "with-tag": !!tag,
        error,
        disabled,
        "input-sm": scale === "sm",
      })}
    >
      {label && !floatingLabel ? (
        <span className="text-14 text-gray-900 mb-2 block">{label}</span>
      ) : null}
      <div className="relative">
        <input
          ref={inputRef}
          type="number"
          inputMode="numeric"
          name={name}
          id={name}
          {...restProps}
          className={cn("input", {
            hasValue: !!inputRef.current?.value || !!value,
            error,
          })}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
        {label && floatingLabel ? (
          <label className={floatingLabel ? "floating" : ""} htmlFor={name}>
            {label}
          </label>
        ) : null}
        <div className="flex absolute right-0 gap-x-4 h-full items-center pr-5">
          <button
            onClick={onDecrease}
            className="appearance-none h-6 w-6 rounded-full bg-[#EEF1F7] text-primary grid place-items-center"
          >
            <RemoveIcon />
          </button>
          <button
            onClick={onIncrease}
            className="appearance-none h-6 w-6 rounded-full bg-[#EEF1F7] text-primary grid place-items-center"
          >
            <PlusIcon />
          </button>
        </div>
      </div>

      {tag ? <div className="tag">{tag}</div> : null}
    </div>
  );
};
