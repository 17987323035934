import { OpnwalletSvcAccount } from "@nestcoinco/onboard-api-gateway-api-client";

export function getTotalBalances(accounts: OpnwalletSvcAccount[]) {
  const result: any = {};
  accounts.forEach((account) => {
    result[account.asset] = {
      availableBalance: account?.balance?.availableBalance,
      lockedAmount:
        account?.balance?.accountBalance - account?.balance?.availableBalance,
    };
  });

  return result;
}
