import { OrderQueryStatus } from "@nestcoinco/onboard-api-gateway-api-client";

export const orderStatusClassGenerator = (status: OrderQueryStatus) => {
  switch (status) {
    case OrderQueryStatus.COMPLETED:
      return "text-green-700";
    case OrderQueryStatus.ONGOING:
      return "text-orange-700";
    default:
      return "text-error";
  }
};
