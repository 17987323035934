import { logout } from "modules/common/utils/logout";
import React from "react";
import ChevronDown from "../Icons/ChevronDown";
import LogoutIcon from "../Icons/LogoutIcon";
import Profile from "../Icons/Profile";
import DropDown from "./DropDown";

const items = [{ onClick: logout, label: "Logout", icon: LogoutIcon }];

const ProfileDropdown = () => {
  return (
    <DropDown
      placeholder={
        <div className="flex gap-1 items-center">
          <Profile />
          <ChevronDown />
        </div>
      }
      items={items}
    />
  );
};

export default ProfileDropdown;
