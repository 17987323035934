import ordersService from "api/ordersService";
import CustomTab from "modules/common/components/Tab/Tab";
import NoTableData from "modules/common/components/Table/NoTableData";
import DashboardTable from "modules/dashboard/dashboardTable";
import React from "react";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import WalletCardsContainer from "modules/common/components/Cards/WalletCardsContainer";
import AccountCardContainer from "modules/common/components/Cards/AccountCardContainer";
import toastNotificationService, {
  ToastTypeEnum,
} from "services/toastNotificationService";

const Dashboard = () => {
  const tabs = [
    { key: "Wallet Balance", content: <WalletCardsContainer /> },
    { key: "Account Balance", content: <AccountCardContainer /> },
  ];

  const { data: orders, error: ordersError } = useQuery("getOrders", () =>
    ordersService.getOrders({ page: 1, size: 5 })
  );

  if (ordersError) {
    toastNotificationService.alert(
      "Error fetching orders",
      ToastTypeEnum.ERROR
    );
  }

  return (
    <>
      <div className="bg-white rounded-[10px] pt-4 mt-10 mb-16">
        <CustomTab
          className="flex justify-center gap-2 mb-6"
          tabItems={tabs}
          dataTestId="dashboard-tab"
        />
      </div>

      <section className="">
        <div className="flex gap-2 mb-4">
          <h3 className="font-semibold text-base">Most Recent Orders</h3>
          <NavLink
            data-testid="view-all"
            to="/orders"
            className="underline text-base"
          >
            View All
          </NavLink>
        </div>
        <div className="bg-white p-4 px-6 rounded-md">
          {orders?.records?.length ? (
            <DashboardTable
              orders={orders?.records!}
              dataTestId="dashboard-order-table"
            />
          ) : (
            <NoTableData message="No order data" dataTestId="no-order" />
          )}
        </div>
      </section>
    </>
  );
};

export default Dashboard;
