import AdsTable from "modules/ads/AdsTable";
import Button from "modules/common/components/Button/Button";
import { ReactComponent as Plus } from "assets/svgs/plus.svg";

import { useNavigate } from "react-router-dom";

const Ads = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex items-baseline gap-4 mb-6 mt-10">
        <p className="mr-auto font-semibold text-2xl">Ads</p>

        <Button
          className="btn btn-primary py-3 rounded-md border-primary-300"
          onClick={() => navigate("/ads/create")}
          dataTestId="create-ad-button"
        >
          <Plus /> <span className="px-1">Create Ad</span>
        </Button>
      </div>
      <div className="pt-10 pb-4 px-6 rounded-[10px] bg-white flex">
        <AdsTable />
      </div>
    </div>
  );
};

export default Ads;
