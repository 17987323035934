import React, { ReactNode } from "react";
import { UseFormRegister, FieldValues } from "react-hook-form";

type OTPInputProps = {
  value?: string;
  children?: ReactNode;
  onChange?: (e: React.SyntheticEvent) => void;
  disabled?: boolean;
  register: UseFormRegister<FieldValues>;
  errors?: any;
  dataTestId?: string;
};

const OtpInput = ({
  onChange,
  register,
  errors,
  dataTestId,
}: OTPInputProps) => {
  return (
    <>
      <input
        {...register("otp")}
        className="otp-input block w-full"
        maxLength={6}
        placeholder={"OTP"}
        data-testid={dataTestId}
      />
      {errors.otp ? (
        <p className="text-error text-center text-sm">{errors.otp.message}</p>
      ) : null}
    </>
  );
};

export default OtpInput;
