import BackButton from "modules/common/components/Button/BackButton";
import React from "react";
import { Outlet } from "react-router-dom";

const WithdrawalLayout = () => {
  return (
    <>
      <div className="w-[900px] mx-auto mb-4">
        <BackButton />
      </div>
      <Outlet />
    </>
  );
};

export default WithdrawalLayout;
