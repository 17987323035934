import APIServices, { createClientInstance } from ".";
import {
  UsersUserauthClient,
  UsersSvcUserType,
  OtpSendResponse,
  AuthOtpResponseDto,
  AuthenticationResponse,
} from "@nestcoinco/onboard-api-gateway-api-client";

class OnboardService extends APIServices {
  private userAuthClient: UsersUserauthClient;

  constructor() {
    super();
    this.userAuthClient = createClientInstance(UsersUserauthClient);
  }

  async requestOTP(email: string): Promise<AuthenticationResponse> {
    try {
      const { data } = await this.userAuthClient.authenticateUser({
        email,
        userType: UsersSvcUserType.PARTNER,
      });
      return data;
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }

  async verifyOtp(
    code: string,
    authSessionId: string
  ): Promise<AuthOtpResponseDto> {
    try {
      const { data } = await this.userAuthClient.verifyMyOtp({
        authSessionId,
        code,
      });
      return data;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  async resendOtp(authSessionId: string): Promise<OtpSendResponse> {
    try {
      const { data } = await this.userAuthClient.resendOtp({ authSessionId });
      return data;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
}

export default new OnboardService();
