import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

class DateService {

  private dateClient
  constructor() {
    this.dateClient = dayjs;
    this.dateClient.extend(relativeTime);
  }

  timeFromNow(dateString: string) {
    return this.dateClient(dateString).fromNow();
  }

  localizeFormat(dateString: string) {
    return this.dateClient(dateString).format('D MMMM, YYYY')
  }
}

export default new DateService();