import React from "react";
import Button from "modules/common/components/Button/Button";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { withdrawalMethod } from "modules/withdrawal/api/atoms";
import classNames from "classnames";
import {
  ONBOARD_P2P,
  STABLE_COIN,
  USD_ACCOUNT,
} from "modules/withdrawal/api/constants";

const withdrawalTypes = [
  {
    label: STABLE_COIN,
    href: "/withdrawal/select-stable-coin",
    title: "To external address",
    active: true,
  },
  { label: USD_ACCOUNT, href: "", title: "To USD Account", active: false },
  { label: ONBOARD_P2P, href: "", title: "via Onboard P2P", active: false },
];

const WithdrawalMethod = () => {
  const navigate = useNavigate();
  const [, setMethod] = useAtom(withdrawalMethod);

  return (
    <div className="mx-auto w-[500px] mt-14 bg-white p-10 rounded-[10px]">
      <h1 className="text-center font-semibold pb-5">
        Select withdrawal method
      </h1>
      <div className="flex flex-col gap-3 rounded-md">
        {withdrawalTypes.map((method) => {
          const { label, active, title, href } = method;
          return (
            <Button
              key={label}
              onClick={() => {
                navigate(href);
                setMethod(label);
              }}
              dataTestId={`method-${title.split(" ").join("-").toLowerCase()}`}
              className={classNames(
                "height-[150px] grid place-items-center rounded-md",
                {
                  "text-primary-300 border border-primary-300": active,
                }
              )}
              disabled={!active}
            >
              {title} {active ? `(${label})` : "coming soon"}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default WithdrawalMethod;
