import * as React from "react";
import { Link } from "react-router-dom";

interface NotFoundProps {
  title?: string;
  subTitle?: string;
}

const NotFound: React.FC<NotFoundProps> = ({
  title = "Page not Found!",
  subTitle = "The page does not exist",
}) => {
  return (
    <div className="grid place-content-center w-full h-[650px] text-center">
      <h3 className="large-title text-center mb-3 text-primary-main">
        {title}
      </h3>
      <p className="footnote text-black">
        {subTitle}
        <br />{" "}
        <Link
          to="/"
          replace
          className="hover:underline text-primary-300 caption-1"
        >
          Go to Dashboard
        </Link>
      </p>
    </div>
  );
};

export default NotFound;
