import React, { ReactNode } from "react";
import TableHead from "./TableHead";

interface TableProps {
  headers?: string[];
  children: ReactNode;
  dataTestId?: string;
  className?: string;
}

const Table = ({ headers, children, dataTestId, className }: TableProps) => {
  return (
    <div className={`w-full ${className}`} data-testid={dataTestId}>
      {headers && <TableHead data={headers} />}
      {children}
    </div>
  );
};

export default Table;
