import React, { ReactNode } from "react";
import cn from "classnames";
import Loader from "../Icons/Loader";

interface ButtonProps {
  className?: string;
  dataTestId?: string
  children: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

const Button = ({
  className,
  children,
  disabled,
  dataTestId,
  ...restProps
}: ButtonProps) => {
  return (
    <button
      className={cn(`btn ${className}`, {
        "!bg-[#CBD5E0] pointer-events-none cursor-not-allowed !text-white":
          disabled,
      })}
      data-testid={dataTestId}
      {...restProps}
    >
      {children}
    </button>
  );
};

interface LodingButtonProps extends ButtonProps {
  loading: boolean;
}

export const LoadingButton = ({
  className,
  children,
  disabled,
  loading,
  ...restProps
}: LodingButtonProps) => {
  return (
    <Button
      disabled={disabled || loading}
      className={`flex items-center gap-2 justify-center ${className}`}
      {...restProps}
    >
      {children}
      {loading && <Loader />}
    </Button>
  );
};

export default Button;
