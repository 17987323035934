import React from "react";
import { NavLink } from "react-router-dom";
import { currencyFormatter } from "utils/common";

const AccountCard = ({ balance, dataTestId }: any) => {
  return (
    <div
      data-testid={dataTestId}
      className="flex basis-[200px] flex-col gap-2 items-center p-6 rounded-md"
    >
      <span className="text-4xl font-bold ">
        {currencyFormatter(balance.totalAmount, balance.currency)}
      </span>
      <span className="text-xs text-green-900">
        <NavLink to="/accounts" className="underline">
          Across <span className="font-semibold">{balance.totalCount}</span>{" "}
          accounts
        </NavLink>
      </span>
    </div>
  );
};

export default AccountCard;
