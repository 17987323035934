import React from "react";
import { usePagination } from "./usePagination";
import { ReactComponent as Dots } from "assets/svgs/pagination-dots.svg";
import cn from "classnames";

const Pagination = ({ size, currentPage, totalItems, setCurrentPage }: any) => {
  const numberRange = usePagination({
    totalCount: totalItems,
    pageSize: size,
    currentPage,
  });

  return (
    <div className="flex justify-center">
      <div className="flex items-center gap-2">
        {numberRange?.map((item, i) => {
          if (item === "DOTS") {
            return <Dots key={i} className="w-4 h-3" />;
          }
          return (
            <button
              key={i}
              className={cn(
                "py-2 px-4 rounded-md border border-gray-100 bg-white text-sm",
                {
                  "pointer-events-none bg-green-50 border-green-50 text-green-900":
                    currentPage === item,
                }
              )}
              onClick={() => setCurrentPage(Number(item))}
            >
              {item}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Pagination;
