import React from "react";

const LoginFailed = () => {
  return (
    <div className="border mt-20 rounded-md p-10 py-4 border-gray-200">
      <h3 className="text-lg text-center font-semibold pb-2 mb-4 border-b border-gray-200">
        Unable to Login
      </h3>
      <p className="text-center text-base">
        Your account has not been provisioned for OnboardFast, contact
        support@onboard.xyz to request access and <a href="/auth/login" className="underline decoration-solid">try again</a>
      </p>
    </div>
  );
};

export default LoginFailed;
