import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "jotai";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import { NODE_ENV, SENTRY_DSN } from "modules/common/api/constants";
import ErrorContainer from "modules/common/components/ErrorContainer/ErrorContainer";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const ErrorScreen = () => (
  <ErrorContainer
    errorTitle="Something went wrong"
    errorMessage="An unexpected error occured. Reload the page and try agai"
  />
);

if (NODE_ENV === "production") {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.05,
    beforeSend(event, hint) {
      const ignoreStatusCodeList = [400, 401, 403];
      const error = hint.originalException as any;
      const statusCode = error?.response?.status ?? null;
      if (statusCode && ignoreStatusCodeList.includes(statusCode)) {
        return null;
      }
      return event;
    },
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider>
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <Sentry.ErrorBoundary fallback={ErrorScreen}>
          <App />
        </Sentry.ErrorBoundary>
      </React.StrictMode>
    </QueryClientProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
