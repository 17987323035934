import { OrdersSvcOrderResponse } from "@nestcoinco/onboard-api-gateway-api-client";
import payoutService from "api/payoutService";
import BackButton from "modules/common/components/Button/BackButton";
import { useQuery } from "react-query";
import dateService from "services/dateService";
import { currencyFormatter } from "utils/common";

type OrderPropsType = {
  data: OrdersSvcOrderResponse;
  setOrder: (order: any) => void;
};

function Order({ data, setOrder }: OrderPropsType) {
  const { data: accountsData } = useQuery("paymentAccounts", () =>
    payoutService.getAccounts({ size: 100 })
  );
  const selectedAccount = accountsData?.accounts.find(
    (account) => account.accountId === data.merchantAccountId
  );
  return (
    <>
      <div className="max-w-[500px] mx-auto pb-8">
        <div className="mb-4">
          <BackButton handleClick={() => setOrder(null)} />
        </div>
        <div className="border border-gray-100 rounded-md p-6 bg-white">
          <h1 className="font-semibold text-xl">Transaction Details</h1>
          <ul>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">Date</span>
              {dateService.localizeFormat(data.createdAt)}
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Amount
              </span>
              USD {currencyFormatter(data.amount)}
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Description
              </span>
              Traded {data.payoutCurrency} for USD
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Order Type
              </span>
              {data.type}
            </li>

            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">Rate</span>
              {currencyFormatter(data.rate, data.payoutCurrency)}
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Total Amount
              </span>
              {currencyFormatter(data.amount * data.rate, data.payoutCurrency)}
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Account
              </span>
              {selectedAccount?.accountName}
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Channel
              </span>
              {data.paymentChannel?.replace("_", " ")}
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Recipient Name
              </span>
              {data.recipient.firstName} {data.recipient.lastName}
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Payout Reference
              </span>
              {data.reference}
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Payout status
              </span>
              {data.status}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Order;
