import React from "react";

const ChevronDown = ({ color }: { color?: string }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-5 h-5"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.64639 3.64654C2.74016 3.55281 2.86731 3.50015 2.99989 3.50015C3.13248 3.50015 3.25963 3.55281 3.35339 3.64654L4.99989 5.29304L6.64639 3.64654C6.69252 3.59879 6.74769 3.56069 6.80869 3.53449C6.86969 3.50829 6.9353 3.49449 7.00169 3.49392C7.06808 3.49334 7.13392 3.50599 7.19537 3.53113C7.25682 3.55627 7.31264 3.5934 7.35959 3.64034C7.40654 3.68729 7.44366 3.74312 7.4688 3.80456C7.49394 3.86601 7.5066 3.93185 7.50602 3.99824C7.50544 4.06463 7.49165 4.13024 7.46544 4.19124C7.43924 4.25224 7.40115 4.30742 7.35339 4.35354L5.35339 6.35354C5.25963 6.44728 5.13248 6.49993 4.99989 6.49993C4.86731 6.49993 4.74016 6.44728 4.64639 6.35354L2.64639 4.35354C2.55266 4.25978 2.5 4.13262 2.5 4.00004C2.5 3.86746 2.55266 3.7403 2.64639 3.64654Z"
        fill={color || "#111928"}
      />
    </svg>
  );
};

export default ChevronDown;
