import { Listbox } from "@headlessui/react";
import Button from "modules/common/components/Button/Button";
import ChevronDown from "modules/common/components/Icons/ChevronDown";

type SelectCurrencyProps = {
  selectedCurrency: string;
  setSelectedCurrency: (data: string) => void;
  next: () => void;
  currencies: string[];
};

const SelectCurrency = ({
  selectedCurrency,
  setSelectedCurrency,
  next,
  currencies,
}: SelectCurrencyProps) => {
  return (
    <div className="flex flex-col mt-14 w-[400px] mx-auto bg-white rounded-[10px] p-10" data-testid="select-currency">
      <h1 className="text-center mb-8 text-lg font-semibold">
        Select payment currency
      </h1>
      <Listbox
        as="div"
        className="relative border rounded-md px-3 mb-4 "
        value={selectedCurrency}
        onChange={setSelectedCurrency}
        data-testid='select-provider-currency'
      >
        <Listbox.Button className="h-12 w-full text-left text-base flex justify-between items-center">
          {selectedCurrency || "Select currency"}
          <ChevronDown />
        </Listbox.Button>
        <Listbox.Options className="absolute left-0 p-3 mt-1 max-h-100 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {currencies.map((currency) => (
            <Listbox.Option
              className="relative cursor-default select-none py-2"
              key={currency}
              value={currency}
              data-testid={`provider-currency-${currency}`}
            >
              {currency}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
      <Button
        className="btn-primary py-3 h-12"
        onClick={() => next()}
        disabled={!selectedCurrency}
        data-testid={`select-provider-currency-btn`}
      >
        Proceed
      </Button>
    </div>
  );
};

export default SelectCurrency;
