import React from "react";
import { RouterProvider } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useAtom } from "jotai";
import { ReactQueryDevtools } from "react-query/devtools";
import "react-toastify/dist/ReactToastify.css";
import { useQuery } from "react-query";
import { router } from "routes";
import { userAtom } from "modules/common/api/userAtom";
import accountService from "api/accountService";
import { getToken } from "modules/common/utils/getToken";
import Loader from "modules/common/components/Icons/Loader";

function App() {
  const setUser = useAtom(userAtom)[1];
  const token = getToken();
  const { data, isLoading } = useQuery(
    "getUserDetails",
    () => accountService.getMyDetails(),
    { enabled: !!token }
  );

  if (isLoading) {
    return (
      <div className="fixed top-0 left-0 h-screen w-screen grid place-items-center bg-backdrop text-center">
        <div className="flex flex-col items-center gap-2">
          loading, please wait
          <Loader stroke="black" />
        </div>
      </div>
    );
  }

  if (data) {
    setUser(data);
  }

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        autoClose={3000}
        icon={false}
        position={toast.POSITION.TOP_RIGHT}
      />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}

export default App;
