import payoutService, { CompleteProviderAuthorizationDataType } from "api/payoutService";
import { routes } from "config/routes";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toastNotificationService, { ToastTypeEnum } from "services/toastNotificationService";


const ConnectAccountWithOAuth = () => {
  const { accounts } = routes
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState({
    name: "",
    id: ""
  })

  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  const [loader, setLoader] = useState(0);

  const completeProviderAuthorization = (payload: CompleteProviderAuthorizationDataType) => {
    return payoutService.completeProviderAuthorization(payload).then((data) => {
      
      if (!data.success) {
        toastNotificationService.alert(data.message!, ToastTypeEnum.ERROR);
        return navigate(accounts.connect,{ replace: true})
      } else {
        navigate(accounts.connectComplete, { replace: true, state: { providerName: provider?.name}})
      }
    }).catch((error) => {
      toastNotificationService.alert(error.message, ToastTypeEnum.ERROR)
    }).finally(() => {
      setLoading(false);
    })
  }

  const getProvider = () => {
    let stringifiedProvider = localStorage.getItem('provider');
    localStorage.removeItem('provider');
    return JSON.parse(stringifiedProvider!);
  }


  useEffect(() => {
    if (!code) {
      toastNotificationService.alert('Error generating code from provider. Try again', ToastTypeEnum.ERROR, 'provider-code-error');
      navigate('/accounts/connect');
      return;
    }
    let count = 0
    let intervalId = setInterval(() => {
      if (!loading) {
        clearInterval(intervalId)
      }
      setLoader(count++ % 4)
    }, 1000)

    const providerInfo = getProvider();
    setProvider(providerInfo)

    if (!providerInfo?.id) {
      toastNotificationService.alert('Provider could not be determined. Try again', ToastTypeEnum.ERROR, 'provider-id-error');
      navigate('/accounts/connect');
      return;
    }

    completeProviderAuthorization({
      providerId: providerInfo.id,
      code
    })


    return () => {
      clearInterval(intervalId);
    }
  }, [])

  return (
    <div className="mx-auto w-[450px] mt-14">
      <div className="p-10 flex flex-col gap-3 rounded-md items-center">
        <h1 className="text-center font-semibold pb-3">
          Connect account
        </h1>
        <p>
          We are processing your request{'.'.repeat(loader)}
        </p>

      </div>
    </div>
  );
};

export default ConnectAccountWithOAuth;
