import { useAtomValue } from "jotai";
import { userAtom } from "modules/common/api/userAtom";
import React, { ReactNode } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

interface ProtectedProps {
  element?: any;
  children?: ReactNode;
  path: string;
}

const ProtectedRoute = ({
  element: Element,
  children,
  path,
}: ProtectedProps) => {
  const location = useLocation();
  const isLoggedIn = !!useAtomValue(userAtom);

  const isPublicPath = (path: string) => {
    const publicPaths = [/\/auth/];
    return publicPaths.some((publicPath) => publicPath.test(path));
  };
  const isVisitor = isPublicPath(location.pathname);

  if (!isLoggedIn && isVisitor) {
    return <Outlet />;
  }

  if (!isLoggedIn) {
    return (
      <Navigate
        to="/auth/login"
        state={{
          from: location.pathname,
          ...(location.state as any),
        }}
        replace
      />
    );
  }

  if (isPublicPath(location.pathname)) {
    return <Navigate to="/dashboard" replace />;
  }

  return Element ? <Element /> : <Outlet />;
};

export default ProtectedRoute;
