import React, { ReactNode, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";

const DropDown = ({
  items,
  placeholder,
  className,
}: {
  items: object[];
  placeholder: ReactNode;
  className?: string;
}) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="grid place-items-center w-full">
        {placeholder}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          as="ul"
          className="absolute right-0 bg-white w-[220px] shadow-lg p-4 rounded-md z-40"
        >
          {items.map((item: any) => {
            const Icon = item.icon;
            return (
              <Menu.Item
                key={item.label}
                as="li"
                className={`text-sm py-2 [&:last-child]:py-0 w-full ${
                  item.className ? item.className : ""
                }`}
              >
                {item.href ? (
                  <NavLink to={item.href}>
                    {item.label} {Icon && <Icon />}
                  </NavLink>
                ) : (
                  <button
                    className={`text-sm py-2 font-normal w-full flex gap-2 items-center ${
                      item.className ? item.className : ""
                    }`}
                    onClick={(e) => {
                      item.onClick();
                    }}
                  >
                    {item.label} {Icon && <Icon />}
                  </button>
                )}
              </Menu.Item>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropDown;
