import { useAtom } from "jotai";
import { otpSchema } from "modules/common/api/formSchema";
import { userAtom } from "modules/common/api/userAtom";
import useCustomForm from "modules/common/hooks/useCustomForm";
import React, { useState } from "react";
import { useEffect } from "react";
import { FieldValues } from "react-hook-form";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import accountService from "../../api/accountService";
import onboardService from "../../api/onboardService";
import onboard from "../../api/onboardService";
import LoginFailed from "../../modules/auth/LoginFailed";
import OtpInput from "../../modules/auth/OtpInput";
import { LoadingButton } from "../../modules/common/components/Button/Button";
import toastNotificationService, {
  ToastTypeEnum,
} from "../../services/toastNotificationService";

interface LocationState {
  email: string;
}

const EnterOTP = () => {
  const setUser = useAtom(userAtom)[1];
  const [error, setError] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = (location?.state || {}) as LocationState;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useCustomForm(otpSchema, { mode: "onSubmit" });

  useEffect(() => {
    setFocus("otp");
  }, [setFocus]);

  function submitForm(data: FieldValues) {
    setLoading(true);
    const authSession = localStorage.getItem("authSession");
    if (!authSession) {
      setOtpError(true);
      return;
    }
    const authSessionId = JSON.parse(authSession).authSessionId;
    onboard
      .verifyOtp(data.otp, authSessionId)
      .then((data) => {
        localStorage.removeItem(authSession);
        localStorage.setItem("accessToken", data.token!);
        accountService
          .getMyDetails()
          .then((data) => {
            setUser(data);
            localStorage.setItem("userData", JSON.stringify(data));
            navigate("/dashboard");
          })
          .catch((err) => {
            toastNotificationService.alert(err, ToastTypeEnum.ERROR);
            setError(true);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        setOtpError(true);
        setTimeout(() => {
          setOtpError(false);
        }, 3000);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleResendOTP() {
    const authSession = localStorage.getItem("authSession");
    if (!authSession) {
      setOtpError(true);
      return;
    }
    const authSessionId = JSON.parse(authSession).authSessionId;
    onboardService
      .resendOtp(authSessionId)
      .then((data) => {
        localStorage.setItem("authSession", JSON.stringify(data));
        // alert
        toastNotificationService.alert("OTP resent", ToastTypeEnum.SUCCESS);
      })
      .catch((err) => {
        // alert
        toastNotificationService.alert(err, ToastTypeEnum.ERROR);
        navigate("/auth/login");
      });
  }

  return (
    <section className="max-w-[35.8rem] mx-auto bg-white">
      {!error ? (
        <>
          <h1 className="text-center mb-12 text-[1.8rem] font-semibold pt-8">
            Onboard fast merchant login
          </h1>
          <form onSubmit={handleSubmit(submitForm)} className="rounded-md p-10">
            <p className="text-center text-sm">
              We have sent a 6-digit code to your email:{" "}
              <span className="font-semibold">{email}</span>, enter the code to
              continue
            </p>

            {otpError ? (
              <p className="text-error text-center text-sm pt-14">
                Invalid or expired token
              </p>
            ) : null}
            <div className="flex flex-col items-center justify-center  gap-4 py-10 pb-14">
              <OtpInput
                dataTestId="otp-input"
                register={register}
                errors={errors}
              />
            </div>
            <div className="flex justify-center items-center gap-2 pb-4">
              <span
                className="bg-transparent text-sm cursor-pointer"
                onClick={handleResendOTP}
                data-testid="resendOtp"
              >
                Re-send OTP
              </span>
              <span className="w-1.5 h-1.5 rounded-full bg-gray-100" />
              <NavLink
                className="text-sm"
                to="/auth/login"
                data-testid="changeEmail"
              >
                Change email
              </NavLink>
            </div>
            <LoadingButton
              dataTestId="continue-button"
              loading={loading}
              className="w-full btn-primary"
            >
              Continue
            </LoadingButton>
          </form>
        </>
      ) : (
        <LoginFailed />
      )}
    </section>
  );
};

export default EnterOTP;
