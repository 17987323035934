import React, { ReactNode } from "react";

const MaxWidthContainer = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div className={`max-w-[76.5rem] px-4 mx-auto ${className}`}>
      {children}
    </div>
  );
};

export default MaxWidthContainer;
