export const convertDateToWords = (date: any) => {
  //convert timestamp to pattern date/month/year e.g 12 May,2022
  let currentYear = new Date().getFullYear();
  let dateYear = new Date(date).getFullYear();
  let dob = new Date(date);
  if (dateYear < currentYear) {
    return dob.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  return dob.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
  });
};
