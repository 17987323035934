import payoutService from "api/payoutService";
import { routes } from "config/routes";
import { calculateCashBalances } from "modules/accounts/api/calculateCashBalances";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import toastNotificationService, {
  ToastTypeEnum,
} from "services/toastNotificationService";
import Button from "../Button/Button";
import Loader from "../Icons/Loader";
import AccountCard from "./AccountCard";
import { MerchantAccountStatus } from "@nestcoinco/onboard-api-gateway-api-client";

const AccountCardContainer = () => {
  const { data, isLoading, error } = useQuery("paymentAccounts", () =>
    payoutService.getAccounts({ size: 100 })
  );

  const accounts =
    data?.accounts.filter(
      (account) => account.status !== MerchantAccountStatus.IN_ACTIVE
    ) || null;

  const cashBalances = useMemo(() => {
    if (accounts) {
      const accountsMap = calculateCashBalances(accounts);
      const result = Object.keys(accountsMap).map((currency: any) => {
        return {
          totalAmount: accountsMap[currency].amount,
          currency,
          totalCount: accountsMap[currency].count,
        };
      });
      return result;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  if (error) {
    toastNotificationService.alert(
      "Cannot fetch accounts data",
      ToastTypeEnum.ERROR
    );
  }

  return (
    <div className="flex justify-center">
      {isLoading ? (
        <div className="flex justify-center">
          <Loader stroke="black" />
        </div>
      ) : accounts?.length ? (
        cashBalances?.map((balance, index) => {
          return (
            <AccountCard
              dataTestId={`account-${index + 1}`}
              key={balance.currency}
              balance={balance}
            />
          );
        })
      ) : (
        <NoAccounts />
      )}
    </div>
  );
};

const NoAccounts = () => {
  const { accounts } = routes;
  const navigate = useNavigate();
  return (
    <section className="flex flex-col justify-between h-[200px] w-[500px] border border-gray-100 p-10 rounded-md">
      <h3>You currently do not have any connected accounts.</h3>
      <Button
        className="btn- py-3 text-primary-300 border border-primary-300"
        onClick={() => navigate(accounts.connect)}
      >
        Add Account
      </Button>
    </section>
  );
};

export default AccountCardContainer;
