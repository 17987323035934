import React from "react";
import { NavLink } from "react-router-dom";
import { currencyFormatter } from "utils/common";
import UpArrow from "../Icons/UpArrow";

interface WalletCardProps {
  currency: string;
  asset: any;
  dataTestId?: string;
}

const WalletCard = ({ currency, asset, dataTestId }: WalletCardProps) => {
  const { lockedAmount, availableBalance } = asset;

  return (
    <div
      data-testid={dataTestId}
      className="rounded-[10px] basis-[30.75rem] bg-[#000D20] text-white p-[36px] px-[25px]"
    >
      <div>
        <div className="mb-5">
          <p className="text-xs">Available balance</p>
          <p className="font-semibold flex items-baseline gap-2">
            <span className="text-[1.875rem]">
              {currencyFormatter(availableBalance, currency)}
            </span>
          </p>

          <p className="text-xs mt-3">Locked balance</p>
          <p className="font-semibold flex items-baseline gap-2">
            <span className="text-sm">
              {currencyFormatter(lockedAmount, currency)}
            </span>
          </p>
        </div>
        <div>
        </div>
      </div>
      <div className="flex justify-end">
        <NavLink
          data-testId="withdraw-icon"
          to="/withdrawal"
          className="flex gap-2 items-center"
        >
          <UpArrow />
          Withdraw
        </NavLink>
      </div>
    </div>
  );
};

export default WalletCard;
