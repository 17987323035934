export function calculateConnectedAds(ads: any, accountId: string) {
  let count = 0;
  ads.forEach((ad: any) => {
    if (ad.paymentAccountId === accountId) {
      count += 1;
    }
  });
  return count;
}

export function getConnectedAds(ads: any, accountId: string) {
  return ads?.filter((ad: any) => {
    return ad.paymentAccountId === accountId;
  });
}
