import React, { useEffect, useRef } from "react";

const useFocusElement = () => {
  const elementRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    elementRef.current?.focus();
  }, []);

  return elementRef!;
};

export default useFocusElement;
