import React from 'react';

const LogoutIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4985_13782)">
        <path
          d="M17.3203 10.4453L14.0391 13.7266C13.9191 13.8412 13.7597 13.9055 13.5938 13.9062C13.5133 13.9065 13.4336 13.8905 13.3594 13.8594C13.2442 13.8128 13.1455 13.7329 13.0759 13.6299C13.0063 13.5269 12.969 13.4055 12.9688 13.2812V10.625H8.125C7.95924 10.625 7.80027 10.5592 7.68306 10.4419C7.56585 10.3247 7.5 10.1658 7.5 10C7.5 9.83424 7.56585 9.67527 7.68306 9.55806C7.80027 9.44085 7.95924 9.375 8.125 9.375H12.9688V6.71875C12.969 6.59447 13.0063 6.47308 13.0759 6.37011C13.1455 6.26713 13.2442 6.18723 13.3594 6.14062C13.4735 6.0953 13.5982 6.08372 13.7187 6.10727C13.8392 6.13082 13.9504 6.18849 14.0391 6.27344L17.3203 9.55469C17.4377 9.67318 17.5035 9.83322 17.5035 10C17.5035 10.1668 17.4377 10.3268 17.3203 10.4453ZM8.125 16.25H3.75V3.75H8.125C8.29076 3.75 8.44973 3.68415 8.56694 3.56694C8.68415 3.44973 8.75 3.29076 8.75 3.125C8.75 2.95924 8.68415 2.80027 8.56694 2.68306C8.44973 2.56585 8.29076 2.5 8.125 2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H8.125C8.29076 17.5 8.44973 17.4342 8.56694 17.3169C8.68415 17.1997 8.75 17.0408 8.75 16.875C8.75 16.7092 8.68415 16.5503 8.56694 16.4331C8.44973 16.3158 8.29076 16.25 8.125 16.25Z"
          fill="#5433FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_4985_13782">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoutIcon;
