import React from 'react';

export const OnboardLogo = () => {
  return (
    <svg
      width="97"
      height="18"
      viewBox="0 0 97 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.0897 9.99314V17.6217H27.9643V11.0624C27.9643 8.14254 27.2035 7.34062 25.5585 7.34062C23.749 7.34062 22.0835 9.37627 22.0835 12.3578V17.6217H18.9375V4.70867H22.0629C22.0629 4.70867 22.0218 7.81355 22.1041 7.62849C22.7004 6.33308 24.1603 4.52361 26.4221 4.52361C29.2186 4.50305 31.0897 6.33308 31.0897 9.99314Z"
        fill="#5433FF"
      />
      <path
        d="M36.3742 6.90888C37.1556 5.53121 38.451 4.50311 40.4661 4.50311C43.8177 4.50311 46.1001 7.44349 46.1001 11.1652C46.1001 14.887 43.8177 17.8274 40.4661 17.8274C38.5127 17.8274 37.1556 16.8198 36.3742 15.401C36.3331 15.3188 36.2919 17.6217 36.2919 17.6217H33.1665V0H36.2919C36.3125 0 36.3331 7.01169 36.3742 6.90888ZM42.9541 11.1652C42.9541 9.10902 41.926 7.34068 39.7258 7.34068C37.6696 7.34068 36.2919 9.10902 36.2919 11.1652C36.2919 13.2214 37.6696 14.9898 39.7258 14.9898C41.926 14.9898 42.9541 13.2214 42.9541 11.1652Z"
        fill="#5433FF"
      />
      <path
        d="M59.8564 11.1653C59.8564 14.8459 57.43 17.8274 53.3999 17.8274C49.3697 17.8274 46.9434 14.8459 46.9434 11.1653C46.9434 7.48468 49.3697 4.50317 53.3999 4.50317C57.43 4.50317 59.8564 7.50524 59.8564 11.1653ZM56.7104 11.1653C56.7104 9.06797 55.5178 7.34074 53.3793 7.34074C51.2408 7.34074 50.0482 9.0474 50.0482 11.1653C50.0482 13.2832 51.2408 14.9899 53.3793 14.9899C55.5178 14.9899 56.7104 13.2832 56.7104 11.1653Z"
        fill="#5433FF"
      />
      <path
        d="M65.3054 10.0138C67.6084 9.89045 69.644 10.1166 69.6234 8.90347C69.6029 8.0193 69.2533 7.05288 67.0326 7.05288C64.8325 7.05288 64.2773 7.95761 64.0922 9.00628H61.0079C61.4397 5.75746 64.2362 4.50317 67.1354 4.50317C70.0964 4.50317 72.7695 5.49016 72.7695 10.4662V17.6218H69.644C69.644 17.6218 69.644 14.887 69.6029 15.0104C68.8627 16.7993 67.4439 17.8274 65.1409 17.8274C62.7146 17.8274 60.6378 16.2647 60.6378 14.0851C60.5967 11.227 62.7146 10.1578 65.3054 10.0138ZM65.84 12.0906C64.5035 12.2345 63.7427 12.7486 63.7427 13.9001C63.7427 14.9693 64.7708 15.6684 66.2101 15.4628C67.8757 15.2366 69.5823 14.1674 69.6029 10.9597C68.8832 11.7822 67.6906 11.9055 65.84 12.0906Z"
        fill="#5433FF"
      />
      <path
        d="M78.1978 7.23788C78.9792 5.44897 79.6989 4.52368 82.8449 4.52368V7.69025C79.2054 7.4435 78.1567 8.73892 78.1567 12.0289V17.6423H75.0312V4.7293H78.1567C78.1361 4.70874 78.1567 7.29957 78.1978 7.23788Z"
        fill="#5433FF"
      />
      <path
        d="M96.272 0V17.6217H93.1465L93.0643 15.401C92.1595 16.7376 90.9258 17.8274 88.9724 17.8274C85.6208 17.8274 83.3384 14.887 83.3384 11.1652C83.3384 7.44349 85.6208 4.50311 88.9724 4.50311C90.9875 4.50311 92.3446 5.49009 93.0643 6.90888C93.1054 7.01169 93.1465 0 93.1465 0H96.272C96.2514 0 96.272 0 96.272 0ZM93.1259 11.1652C93.1259 9.10902 91.7483 7.34068 89.6921 7.34068C87.4919 7.34068 86.4638 9.10902 86.4638 11.1652C86.4638 13.2214 87.4919 14.9898 89.6921 14.9898C91.7483 14.9898 93.1259 13.2214 93.1259 11.1652Z"
        fill="#5433FF"
      />
      <path
        d="M8.61553 0.205566C3.22826 0.205566 0 4.15349 0 9.02672C0 13.8999 3.22826 17.8479 8.61553 17.8479C14.0028 17.8479 17.2311 13.8999 17.2311 9.02672C17.2311 4.15349 14.0028 0.205566 8.61553 0.205566ZM13.7766 8.94447L8.55385 14.1673C7.15562 15.5655 4.87323 15.5655 3.45444 14.1673C2.05621 12.769 2.05621 10.4866 3.45444 9.06785L8.67722 3.84506C10.0754 2.44683 12.3578 2.44683 13.7766 3.84506C15.1749 5.26385 15.1749 7.54625 13.7766 8.94447Z"
        fill="#5433FF"
      />
    </svg>
  );
};
