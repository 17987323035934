import React, { FC, ReactNode } from "react";
import { AlertDangerIcon } from "../Icons/AlertDangerIcon";
import { CancelIcon } from "../Icons/CancelIcon";
import { HourGlassIcon } from "../Icons/HourGlassIcon";
import { InfoIcon } from "../Icons/InfoIcon";
import cn from "classnames";

export type AlertSeverityProp = "success" | "warning" | "danger" | "info";
export interface AlertProps {
  severity?: AlertSeverityProp;
  variant?: "light" | "contained";
  title?: string;
  content?: ReactNode | string;
  onClose?: VoidFunction;
  rounded?: boolean;
  icon?: ReactNode | false;
  className?: string;
  titleClass?: string;
  contentClass?: string;
  scrollable?: boolean;
  children?: ReactNode;
}

const getAlertIcon = (severity: AlertSeverityProp) => {
  switch (severity) {
    case "danger":
      return <AlertDangerIcon />;
    case "info":
      return <InfoIcon fill="#5433FF" />;
    case "warning":
    case "success":
      return <HourGlassIcon />;
  }
};

const variantSeverityMap: Record<string, string> = {
  "light-success": "bg-alert-light-success",
  "contained-success": "bg-alert-contained-success",
  "light-warning": "bg-alert-light-warning",
  "contained-warning": "bg-alert-contained-warning",
  "light-danger": "bg-alert-light-danger",
  "contained-danger": "bg-alert-contained-danger",
  "light-info": "bg-alert-light-info",
  "contained-info": "bg-alert-contained-info",
};

export const Alert: FC<AlertProps> = ({
  icon,
  onClose,
  severity = "success",
  title,
  content,
  rounded,
  variant,
  children,
  className,
  titleClass,
  contentClass,
  scrollable = false,
}) => {
  return (
    <div
      className={cn(
        `relative w-full flex py-3.5 px-5 `,
        variantSeverityMap[`${variant}-${severity}`],
        className,
        {
          "rounded-[10px]": rounded,
        }
      )}
    >
      {onClose ? (
        <button onClick={onClose} className="absolute right-4 top-4">
          <CancelIcon />
        </button>
      ) : null}
      {icon !== false ? (
        <div className="mr-2.5">{icon || getAlertIcon(severity)}</div>
      ) : null}
      <div
        className={cn("w-full flex flex-col justify-center", {
          "pr-[15px]": !!onClose,
          "overflow-x-hidden": !scrollable,
          "overflow-x-scroll": scrollable,
        })}
      >
        {title ? (
          <h5
            className={cn("font-semibold text-14", titleClass, {
              "mb-2": !!content,
            })}
          >
            {title}
          </h5>
        ) : null}
        <div className="text-13 font-normal">
          {content ? <p className={cn(contentClass)}>{content}</p> : children}
        </div>
      </div>
    </div>
  );
};
