import React from 'react';

export const InfoIcon = ({ fill = '#F9B800' }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="warning">
      <ellipse id="Oval" cx="8" cy="8.69878" rx="8" ry="7.99077" fill={fill} />
      <g id="Group">
        <path
          id="Vector"
          d="M9.48639 12.0288L9.36064 12.5428C8.98339 12.6917 8.68203 12.805 8.45763 12.883C8.23299 12.9611 7.97197 13 7.67457 13C7.21786 13 6.86263 12.8881 6.60927 12.6659C6.35591 12.4428 6.22919 12.1601 6.22919 11.8171C6.22919 11.6843 6.23839 11.5478 6.2575 11.4086C6.27678 11.2693 6.30743 11.1124 6.34938 10.9371L6.82085 9.26834C6.86279 9.10855 6.89844 8.95715 6.927 8.81398C6.95595 8.67169 6.96991 8.54077 6.96991 8.42293C6.96991 8.20982 6.92579 8.06076 6.83795 7.97695C6.7501 7.89338 6.58249 7.85095 6.33365 7.85095C6.21177 7.85095 6.0865 7.87047 5.95881 7.90822C5.83056 7.94614 5.72094 7.98276 5.62891 8.0168L5.75498 7.50233C6.06383 7.37658 6.35906 7.2689 6.64145 7.17953C6.92385 7.08991 7.19068 7.04506 7.44307 7.04506C7.89663 7.04506 8.24662 7.15468 8.4924 7.37392C8.73818 7.59332 8.86102 7.87773 8.86102 8.22821C8.86102 8.30072 8.85296 8.42849 8.83561 8.61111C8.81868 8.79413 8.78714 8.96183 8.74116 9.11436L8.27171 10.7764C8.23323 10.9099 8.19863 11.0625 8.16854 11.2342C8.13749 11.4048 8.12265 11.5352 8.12265 11.6226C8.12265 11.8433 8.17185 11.994 8.2705 12.0741C8.36987 12.1542 8.54104 12.194 8.78415 12.194C8.89829 12.194 9.02815 12.1738 9.17246 12.134C9.31636 12.0942 9.4213 12.0593 9.48639 12.0288ZM9.60545 5.05102C9.60545 5.3406 9.49631 5.58791 9.277 5.79126C9.05824 5.99533 8.79464 6.09745 8.48627 6.09745C8.17693 6.09745 7.91268 5.99533 7.69143 5.79126C7.47058 5.58783 7.35991 5.3406 7.35991 5.05102C7.35991 4.76201 7.47058 4.5143 7.69143 4.30837C7.91228 4.10276 8.17701 4 8.48627 4C8.79456 4 9.05824 4.10301 9.277 4.30837C9.49648 4.5143 9.60545 4.76209 9.60545 5.05102Z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);
