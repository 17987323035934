import APIServices, { config, createClientInstance } from ".";
import {
  OrdersAdsClient,
  AdsDto,
  OrdersQuotesClient,
  RateDto,
  CreateAdDto,
  UpdateAdDto,
  OrdersOrdersClient,
  OrderQueryStatus,
} from "@nestcoinco/onboard-api-gateway-api-client";

class OrderService extends APIServices {
  private adsClient: OrdersAdsClient;
  private quoteClient: OrdersQuotesClient;
  private ordersClient: OrdersOrdersClient;

  constructor() {
    super();
    this.adsClient = createClientInstance(OrdersAdsClient);
    this.quoteClient = createClientInstance(OrdersQuotesClient);
    this.ordersClient = createClientInstance(OrdersOrdersClient);
  }

  async getAds(): Promise<AdsDto> {
    try {
      const { data } = await this.adsClient.getMerchantAds({
        headers: {
          ...this.headers,
          "x-auth-token": this.getToken(),
        },
      });

      return data;
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }

  async getOrders({
    orderStatus = undefined,
    page = 1,
    size = 8,
    accountId = undefined,
  }: {
    orderStatus?: OrderQueryStatus;
    page: number;
    size: number;
    accountId?: string;
  }) {
    try {
      const { data } = await this.ordersClient.getMyOrders(
        orderStatus,
        accountId,
        page,
        size,
        {
          headers: {
            ...this.headers,
            "x-auth-token": this.getToken(),
          },
        }
      );

      return data;
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }

  async getPaymentAccountAds(accountId: string) {
    try {
      const { data } = await this.adsClient.getPaymentAccountAds(accountId, {
        headers: {
          ...this.headers,
          "x-auth-token": this.getToken(),
        },
      });

      return data;
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }

  async deleteAd(id: string) {
    try {
      const { data } = await this.adsClient.deleteAd(id, {
        headers: {
          ...this.headers,
          "x-auth-token": this.getToken(),
        },
      });

      return data;
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }

  async getRates(currency: string): Promise<RateDto> {
    const { data } = await this.quoteClient.getRate(currency, {
      headers: {
        ...this.headers,
        "x-auth-token": this.getToken(),
      },
    });

    return data;
  }

  async createAd(createAdData: CreateAdDto) {
    try {
      const { data } = await this.adsClient.createAd(createAdData, {
        headers: {
          ...this.headers,
          "x-auth-token": this.getToken(),
        },
      });

      return Promise.resolve(data);
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }

  async updateAd(adId: string, updateData: UpdateAdDto) {
    try {
      const { data } = await this.adsClient.updateAd(adId, updateData, {
        headers: {
          ...this.headers,
          "x-auth-token": this.getToken(),
        },
      });
      return Promise.resolve(data);
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }

  async disableAd(id: string) {
    try {
      const { data } = await this.adsClient.disableAd(id, {
        headers: {
          ...this.headers,
          "x-auth-token": this.getToken(),
        },
      });

      return data;
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }

  async enableAd(id: string) {
    try {
      const { data } = await this.adsClient.enableAd(id, {
        headers: {
          ...this.headers,
          "x-auth-token": this.getToken(),
        },
      });

      return data;
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }
}

export default new OrderService();
