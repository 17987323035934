import BackButton from "modules/common/components/Button/BackButton";
import React from "react";

function OrderId() {
  return (
    <>
      <div className="max-w-[500px] mx-auto pb-8">
        <div className="mb-4">
          <BackButton />
        </div>
        <div className="border border-gray-100 rounded-md p-6 bg-white">
          <h1 className="font-semibold text-xl">Transaction Details</h1>
          <ul>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">Date</span>
              5th April, 2022
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Amount
              </span>
              USD 100
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Description
              </span>
              Traded NGN for USD
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">Fee</span>
              USD 1.00
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Order Type
              </span>
              Buy
            </li>

            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">Rate</span>
              NGn 754
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Total Amount
              </span>
              NGN 75,400.00
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Account
              </span>
              Paga
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Channel
              </span>
              Bank Transfer
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Recipient Name
              </span>
              Jonah House
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Payout Reference
              </span>
              0x120010101010101101010101011a
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Payout status
              </span>
              Ongoing
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default OrderId;
