import React, { useEffect, useState } from "react";
import { LoadingButton } from "modules/common/components/Button/Button";
import { useNavigate } from "react-router-dom";
import OtpInput from "modules/auth/OtpInput";
import useCustomForm from "modules/common/hooks/useCustomForm";
import { otpSchema } from "modules/common/api/formSchema";
import { FieldValues } from "react-hook-form";
import walletService from "api/walletService";
import { useAtom } from "jotai";
import {
  externalAddressState,
  selectedNetwork,
  selectedToken,
  withdrawalDetails,
} from "modules/withdrawal/api/externalAddressAtoms";
import { useQuery } from "react-query";
import toastNotificationService, { ToastTypeEnum, } from "services/toastNotificationService";
import { RESET, useUpdateAtom } from "jotai/utils";
import accountService from "api/accountService";
import { OpnwalletSvcAccount } from "@nestcoinco/onboard-api-gateway-api-client";

const ConfirmWithdrawal = () => {
  const navigate = useNavigate();
  const [state, setState] = useAtom(externalAddressState);
  const setToken = useUpdateAtom(selectedToken);
  const setNetwork = useUpdateAtom(selectedNetwork);
  const setWithdrawalDetails = useUpdateAtom(withdrawalDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [wallet, setWallet] = useState<OpnwalletSvcAccount | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useCustomForm(otpSchema, {
    mode: "onChange",
  });

  const { data: userData } = useQuery("getUserDetails", () =>
    accountService.getMyDetails()
  );


  const { data: wallets } = useQuery("getWallets", () =>
    walletService.getWallets()
  );

  useEffect(() => {
    const foundWallet = wallets?.find((wallet) => wallet.asset === state.asset)!;
    if (!foundWallet) {
      toastNotificationService.alert(
        "Unable to load your wallet",
        ToastTypeEnum.ERROR
      );
      setWithdrawalDetails(RESET);
      navigate("/");
      return;
    }
    setWallet(foundWallet);
  }, [
    wallets,
    setWallet,
    navigate,
    state,
    setState,
    setWithdrawalDetails
  ]);

  useEffect(() => {
    if (!state) {
      navigate("/withdrawal");
    }
  }, [state, navigate]);

  useEffect(() => {
    setIsDisabled(!isValid);
  }, [isValid]);

  async function sendRequest({ otp }: FieldValues) {
    setIsLoading(true);
    const request = {
      sourceAccountId: `${wallet?.id}`,
      amount: state.amount,
      tokenNetworkCode: state.network.code,
      otp,
      recipient: {
        address: state.walletAddress,
        memo: state.memo || undefined,
      },
    };
    let isFailed = false;
    try {
      await walletService.initiateWithdrawal(request);
      navigate("/withdrawal/success");
    } catch (err: any) {
      let message = "Cannot process withdrawal request";
      if(err?.code === 'OTP_VERIFICATION_FAILED') {
        message = "The provided OTP is invalid";
      }
      isFailed = true;
      toastNotificationService.alert(
        message,
        ToastTypeEnum.ERROR
      );
      navigate("/withdrawal/complete-withdrawal");
    } finally {
      setIsLoading(false);

      if(!isFailed) {
        setWithdrawalDetails(RESET);
        setNetwork(RESET);
        setToken(RESET);
        setState(null);
      }
    }
  }

  return (
    <div className="mx-auto w-[450px] mt-14 p-10 bg-white rounded-[10px]">
      <h1 className="text-center font-semibold pb-3">Confirm Withdrawal</h1>
      <form onSubmit={handleSubmit(sendRequest)} className="w-full">
        <p className="text-center text-sm">
          Enter OTP sent to your phone number.{" "}
          <span className="font-semibold">{userData?.phoneNumber}</span>
        </p>
        <div className="py-10 pb-14 text-center">
          <OtpInput register={register} errors={errors}/>
        </div>
        <LoadingButton loading={isLoading} className="w-full btn-primary" disabled={isDisabled}>
          Confirm Withdrawal
        </LoadingButton>
      </form>
    </div>
  );
};

export default ConfirmWithdrawal;
