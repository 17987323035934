import React from "react";

const UpArrow = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.5" cy="10.5" r="9.75" stroke="#A4FECB" strokeWidth="1.5" />
      <path
        d="M7.5 9L10.5 6L13.5 9"
        stroke="#A4FECB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 7.5L10.5 13.5"
        stroke="#A4FECB"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default UpArrow;
