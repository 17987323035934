import { OrdersSvcOrderResponse } from "@nestcoinco/onboard-api-gateway-api-client";
import Loader from "modules/common/components/Icons/Loader";
import Table from "modules/common/components/Table";
import TableBodyRow from "modules/common/components/Table/TableBodyRow";
import { orderStatusClassGenerator } from "modules/common/utils/classGenerator";
import { enumToReadable } from "modules/common/utils/enumFormatters";
import React from "react";
import dateService from "services/dateService";
import { currencyFormatter } from "utils/common";

const headers = [
  "Time",
  "Type",
  "Amount",
  "Status",
  "Rate",
  "Local currency",
  "Local amount",
  "",
];

type OrderPropsType = {
  data: Array<OrdersSvcOrderResponse>;
  setOrder: (order: OrdersSvcOrderResponse) => void;
  loading: boolean;
};

const OrdersTable = ({ data, setOrder, loading }: OrderPropsType) => {
  return (
    <div className="mb-6 p-4 px-6 rounded-md bg-white flex">
      <Table headers={headers} dataTestId="order-table">
        {loading ? (
          <div className="flex justify-center mt-4">
            <Loader stroke="black" />
          </div>
        ) : data.length ? (
          data.map((item, index) => {
            const { createdAt, status, amount, type, rate, payoutCurrency } =
              item;

            const orderData = [
              dateService.timeFromNow(createdAt),
              <p className={`text-${type === "BUY" ? "green-700" : "error"}`}>
                {enumToReadable(type)}
              </p>,
              amount,
              <p className={orderStatusClassGenerator(status)}>
                {enumToReadable(status)}
              </p>,
              rate,
              payoutCurrency,
              currencyFormatter(rate * amount, payoutCurrency),
              <p
                className="text-primary-300 cursor-pointer"
                onClick={() => {
                  setOrder(item);
                }}
              >
                View
              </p>,
            ];

            return <TableBodyRow data={orderData} key={index} />;
          })
        ) : (
          <tr>
            <td colSpan={8} className="text-center pt-8">
              No data available
            </td>
          </tr>
        )}
      </Table>
    </div>
  );
};

export default OrdersTable;
