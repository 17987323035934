import { LoadingButton } from "../../modules/common/components/Button/Button";
import onboardService from "../../api/onboardService";
import { useNavigate } from "react-router-dom";
import React from "react";
import toastNotificationService, {
  ToastTypeEnum,
} from "../../services/toastNotificationService";
import useFocusElement from "modules/common/hooks/useFocusElement";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const elementRef = useFocusElement();

  function submitForm(e: React.SyntheticEvent) {
    setLoading(true);
    e.preventDefault();
    onboardService
      .requestOTP(email)
      .then((data) => {
        localStorage.setItem("authSession", JSON.stringify(data));
        toastNotificationService.alert("OTP sent", ToastTypeEnum.SUCCESS);
        setLoading(false);
        navigate("/auth/otp", {
          state: {
            email,
          },
        });
      })
      .catch((err) => {
        toastNotificationService.alert(err.message, ToastTypeEnum.ERROR);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <section className="max-w-[35.8rem] p-10 mx-auto bg-white">
      <h1 className="text-center mb-12 text-[1.8rem] font-semibold">
        Onboard fast merchant login
      </h1>
      <form onSubmit={submitForm} className="w-full bg-white">
        <input
          ref={elementRef}
          data-testid="login-input"
          className="p-4 mb-6 w-full border placeholder:text-grey-500 rounded-lg"
          placeholder="user@email.com"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <LoadingButton
          dataTestId="login-button"
          loading={loading}
          className="w-full btn-primary"
          disabled={!email}
        >
          Login
        </LoadingButton>
      </form>
    </section>
  );
};

export default Login;
