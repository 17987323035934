import AddressValidator from "trezor-address-validator";
import MultiCoinValidator from "multicoin-address-validator";

export const range = (start: number, end: number) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const validateAssetAddress = (
  assetType: string,
  address: string,
  network?: "testnet" | "prod"
): boolean => {
  if (assetType !== "BUSD") {
    return MultiCoinValidator.validate(address, assetType, network || "prod");
  }
  return AddressValidator.validate(address, assetType, network || "prod");
};

export const getPercentageValue = (percentage: number, value: number) => {
  if (!value) return 0;
  return percentage * value;
};
