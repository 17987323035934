import APIServices, { config, createClientInstance } from ".";
import {   
  MerchantAccountResponse,
  PayoutsMerchantsClient,
  PayoutProvider,
  PayoutsProvidersClient,
  StatusDto,
  MerchantAccountListResponse
 } from '@nestcoinco/onboard-api-gateway-api-client';

export type InitiateProviderAuthorizationDataType = {
  providerId: string
} & ({
  username: string,
  password: string,
} | {
  apiKey: string,
  apiSecret: string
})

export type CompleteProviderAuthorizationDataType = {
  providerId: string
} & ({
  username: string,
  password: string,
  otp?: string
} | {
  apiKey: string,
  apiSecret: string,
  otp?: string
} | {
  otp: string
} | {
  code: string
})

export type Status = StatusDto & {
  status?: boolean
  data?: {
    accountId?: string,
    action?: string
  }
}

class PayOutService extends APIServices {
  private merchantsClient: PayoutsMerchantsClient
  private providersClient: PayoutsProvidersClient

  constructor() {
    super();
    this.merchantsClient = createClientInstance(PayoutsMerchantsClient);
    this.providersClient = createClientInstance(PayoutsProvidersClient);
  }

  async getAccounts({page, size}:{page?: number; size?: number;}): Promise<MerchantAccountListResponse> {
    try {
      const { data } = await this.merchantsClient.getMyMerchantAccounts(page, size, {
        headers: {
          ...this.headers,
          "x-auth-token": this.getToken(),
        },
      });

      return Promise.resolve(data!);
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }

  async getSingleAccount(accountId: string): Promise<MerchantAccountResponse> {
    try {
      const { data } = await this.merchantsClient.getMyMerchantAccount(
        accountId,
        {
          headers: {
            ...this.headers,
            "x-auth-token": this.getToken(),
          },
        }
      );

      return Promise.resolve(data!);
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }

  async disconnectAccount(accountId: string) {
    try {
      const { data } =
        await this.merchantsClient.revokeAccessToMyMerchantAccount(accountId, {
          headers: {
            ...this.headers,
            "x-auth-token": this.getToken(),
          },
        });

      return Promise.resolve(data!);
    } catch (error: any) {
      return await this.responseHandler(error.response);
    }
  }
  async getProviders(): Promise<Array<PayoutProvider>> {
    try {
      const { data } = await this.providersClient.getAvailableProviders({
        headers: {
          ...this.headers,
          'x-auth-token': this.getToken()
        }    
      });

      return Promise.resolve(data.providers)
    } catch (error: any) {
      return await this.responseHandler(error.response)
    }
  }

  async initiateProviderAuthorization(initiateProviderAuthorizationData: InitiateProviderAuthorizationDataType): Promise<StatusDto> {
    try {
  
      const { data } = await this.merchantsClient.initiateProviderAuthorization(initiateProviderAuthorizationData, {
        headers: {
          ...this.headers,
          'x-auth-token': this.getToken()
        }
      })

      return Promise.resolve(data);
  
    } catch (error: any) {
      return await this.responseHandler(error.response)
    }
  }

  async completeProviderAuthorization(completeProviderAuthorizationData: CompleteProviderAuthorizationDataType, accountId?: string ): Promise<StatusDto> {

    try { 
      const { data } = await this.merchantsClient.completeProviderAuthorization(completeProviderAuthorizationData, accountId, {
        headers: {
          ...this.headers,
          'x-auth-token': this.getToken()
        }
      })
  
      return Promise.resolve(data);
      
    } catch (error: any) {
      return await this.responseHandler(error.response)
    }
  }

}

export default new PayOutService();
