import classNames from "classnames";
import React from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";

type InputProps = {
  register: UseFormRegister<FieldValues>;
  label: string;
  errors?: any;
  type?: string;
  placeholder: string;
  labelText?: string;
  dataTestId?: string;
  errorId?: string;
};

const Input = ({
  label,
  errors,
  type = "text",
  register,
  labelText,
  dataTestId,
  errorId,
  ...restProps
}: InputProps) => {
  return (
    <>
      {labelText && (
        <label className="text-sm font-semibold capitalize">{labelText}</label>
      )}
      <input
        className={classNames(
          "p-4 w-full border placeholder:text-grey-500 rounded-lg",
          { "border-error text-error": errors[label] }
        )}
        data-testid={dataTestId}
        type={type}
        {...register(label)}
        {...restProps}
      />
      {errors[label] ? (
        <p className="text-error text-sm pt-2 " data-testid={errorId}>
          {errors[label].message}
        </p>
      ) : null}
    </>
  );
};

export default Input;
