import Table from "modules/common/components/Table";
import { convertDateToWords } from "modules/common/utils/dateFormatter";
import { OrdersSvcOrderResponse } from "@nestcoinco/onboard-api-gateway-api-client";
import TableBodyRow from "modules/common/components/Table/TableBodyRow";
import { orderStatusClassGenerator } from "modules/common/utils/classGenerator";
import { enumToReadable } from "modules/common/utils/enumFormatters";
import { currencyFormatter } from "utils/common";

const DashboardTable = ({
  orders,
  dataTestId,
}: {
  orders: OrdersSvcOrderResponse[];
  dataTestId?: string;
}) => {
  return (
    <>
      <Table dataTestId={dataTestId}>
        {orders?.map((order, index: number) => {
          const { createdAt, rate, payoutCurrency, amount, status, recipient } =
            order;

          const orderDataList = [
            <div className="flex gap-4">
              <td className="basis-[150px]">{convertDateToWords(createdAt)}</td>
              <td className="flex-1 text-left">
                <span>
                  Payout of{" "}
                  <span className="font-semibold">
                    {currencyFormatter(
                      (rate * amount).toFixed(2),
                      payoutCurrency
                    )}
                  </span>{" "}
                  to {recipient.firstName} {recipient.lastName}.
                </span>{" "}
                <span>
                  <span className="font-semibold">
                    {currencyFormatter(amount, "USDX")}
                  </span>{" "}
                  at the rate of{" "}
                  <span className="font-semibold">
                    {currencyFormatter(rate.toFixed(2), payoutCurrency)}
                  </span>{" "}
                </span>
              </td>
              <span
                className={`basis-[150px] ${orderStatusClassGenerator(status)}`}
              >
                {enumToReadable(status)}
              </span>
            </div>,
          ];

          return <TableBodyRow data={orderDataList} key={index} />;
        })}
      </Table>
    </>
  );
};

export default DashboardTable;
