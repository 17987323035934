import React from "react";
import MaxWidthContainer from "../components/MaxWidthContainer/MaxWidthContainer";
import { Outlet } from "react-router-dom";
import Header from "../components/Headers/Header";

const DefaultLayout = () => {
  return (
    <>
      <Header />
      <MaxWidthContainer>
        <Outlet />
      </MaxWidthContainer>
    </>
  );
};

export default DefaultLayout;
