import React from "react";

const SuccessCheck = () => {
  return (
    <svg
      width="73"
      height="71"
      viewBox="0 0 73 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.7809 1.7674C39.1498 1.7674 41.3607 9.16525 44.4137 10.103C47.5721 11.145 53.6782 6.45617 56.4155 8.33169C59.0475 10.2072 56.5208 17.5009 58.4158 20.1057C60.3108 22.7106 68.1014 22.5022 69.1542 25.6281C70.1017 28.6497 63.785 33.0259 63.785 36.3602C63.785 39.6944 70.207 44.0706 69.1542 47.0923C68.1014 50.2181 60.3108 50.0097 58.4158 52.6146C56.5208 55.2195 59.0475 62.5132 56.4155 64.3887C53.7835 66.2642 47.6774 61.5754 44.4137 62.6173C41.3607 63.5551 39.1498 70.9529 35.7809 70.9529C32.412 70.9529 30.2011 63.5551 27.1481 62.6173C23.9897 61.5754 17.8836 66.2642 15.1463 64.3887C12.5144 62.5132 15.041 55.2195 13.146 52.6146C11.251 50.0097 3.46041 50.2181 2.40763 47.0923C1.46012 44.0706 7.77683 39.6944 7.77683 36.3602C7.77683 33.0259 1.35484 28.6497 2.40763 25.6281C3.46041 22.5022 11.251 22.7106 13.146 20.1057C15.041 17.5009 12.5144 10.2072 15.1463 8.33169C17.7783 6.45617 23.8844 11.145 27.1481 10.103C30.2011 9.16525 32.412 1.7674 35.7809 1.7674Z"
        fill="#22C55E"
      />
      <path
        d="M35.7838 6.87701C38.7316 6.87701 40.5213 13.1287 43.1533 13.9623C45.8905 14.7958 51.0492 10.8364 53.3653 12.5036C55.6814 14.1707 53.4706 20.3182 55.0498 22.6105C56.7342 24.7986 63.2615 24.6944 64.209 27.2993C65.0512 29.9041 59.5767 33.551 59.5767 36.4684C59.5767 39.3859 65.0512 43.0327 64.209 45.6376C63.3667 48.3467 56.7342 48.1383 55.0498 50.3264C53.3653 52.6187 55.5761 58.7662 53.3653 60.4333C51.1544 62.1004 45.8905 58.0368 43.1533 58.9746C40.5213 59.8081 38.7316 66.0598 35.7838 66.0598C32.836 66.0598 31.0463 59.8081 28.4143 58.9746C25.6771 58.141 20.5184 62.1004 18.2023 60.4333C15.8862 58.7662 18.097 52.6187 16.5178 50.3264C14.8334 48.1383 8.3061 48.2425 7.3586 45.6376C6.51637 43.0327 11.9909 39.3859 11.9909 36.4684C11.9909 33.551 6.51637 29.9041 7.3586 27.2993C8.20083 24.5902 14.8334 24.7986 16.5178 22.6105C18.2023 20.3182 15.9914 14.1707 18.2023 12.5036C20.4131 10.8364 25.6771 14.9 28.4143 13.9623C31.0463 13.2329 32.836 6.87701 35.7838 6.87701Z"
        fill="#22C55E"
      />
      <path
        d="M25.8848 36.4657L32.5173 43.03L45.6771 29.9014"
        stroke="white"
        strokeWidth="3.551"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.8939 9.58694L70.1045 10.6289C70.2098 10.9415 70.4203 11.2541 70.8414 11.3583L71.7889 11.6708C72.4206 11.8792 72.6312 12.6086 72.4206 13.1296C72.3153 13.338 72.21 13.5464 71.9995 13.6506L71.1573 14.2757C70.8414 14.4841 70.6309 14.7967 70.6309 15.2135V16.2554C70.6309 16.8806 70.1045 17.2974 69.5781 17.2974C69.3675 17.2974 69.0517 17.1932 68.9464 17.089L68.2095 16.4638C67.8936 16.2554 67.5778 16.1512 67.262 16.2554L66.3145 16.568C65.6828 16.7764 65.1564 16.3596 64.9458 15.8387C64.8405 15.6303 64.9458 15.3177 64.9458 15.1093L65.3669 14.1715C65.4722 13.8589 65.4722 13.4422 65.2617 13.1296L64.7353 12.296C64.4194 11.775 64.5247 11.0457 65.0511 10.7331C65.2617 10.6289 65.4722 10.5247 65.6828 10.5247H66.7356C67.0514 10.5247 67.4725 10.4205 67.6831 10.1079L68.3147 9.27436C68.6306 8.85758 69.3675 8.75338 69.7886 9.06597C69.6834 9.17016 69.7886 9.37855 69.8939 9.58694Z"
        fill="#A4FECB"
      />
      <path
        d="M3.46392 55.1203L4.41143 55.6413C4.72726 55.8497 5.14838 55.8497 5.46421 55.6413L6.30644 55.2245C6.83283 55.0161 7.56978 55.2245 7.78034 55.8497C7.88562 56.0581 7.88562 56.2665 7.88562 56.4749L7.67506 57.5168C7.56978 57.8294 7.67506 58.2462 7.9909 58.4546L8.72784 59.1839C9.14896 59.6007 9.14896 60.3301 8.72784 60.7469C8.51729 60.9553 8.30673 61.0594 8.09618 61.0594L7.04339 61.1636C6.72755 61.1636 6.41172 61.372 6.20116 61.6846L5.78005 62.6224C5.46421 63.1433 4.83254 63.3517 4.30615 63.0392C4.09559 62.935 3.88503 62.7266 3.77976 62.5182L3.35864 61.5804C3.25336 61.2678 2.93753 61.0594 2.51641 60.9553L1.46363 60.7469C0.831958 60.6427 0.410844 60.0175 0.516123 59.3923C0.516123 59.1839 0.6214 58.9755 0.831957 58.7672L1.56891 58.0378C1.88474 57.8294 1.99002 57.4126 1.88474 57.1L1.67419 56.0581C1.56891 55.4329 1.99002 54.9119 2.62169 54.8077C3.04281 55.0161 3.25336 55.0161 3.46392 55.1203Z"
        fill="#A4FECB"
      />
      <path
        d="M23.1509 0.104196L23.572 0.312586C23.6773 0.416781 23.8878 0.416781 24.0984 0.312586L24.5195 0.104196C24.8353 3.9592e-07 25.1512 0.104195 25.2565 0.41678C25.2565 0.520975 25.3617 0.625171 25.2565 0.729366V1.25034C25.2565 1.45873 25.2565 1.56293 25.3617 1.77132L25.6776 2.1881C25.8881 2.39649 25.8881 2.70907 25.6776 2.91746C25.5723 3.02166 25.467 3.02166 25.3617 3.02166H24.8353C24.6248 3.02166 24.5195 3.12585 24.4142 3.33424L24.2037 3.75102C24.0984 3.95941 23.7826 4.06361 23.4667 3.95941C23.3614 3.85522 23.2562 3.85522 23.2562 3.75102L23.1509 3.23005C23.0456 3.02166 22.9403 2.91746 22.7298 2.91746L22.2034 2.81327C21.8875 2.70907 21.677 2.50068 21.7823 2.1881C21.7823 2.0839 21.8875 1.97971 21.8875 1.87551L22.2034 1.56293C22.3087 1.45873 22.4139 1.25034 22.4139 1.14615L22.3087 0.625171C22.3087 0.312586 22.4139 0.104195 22.7298 0C22.9403 0 23.0456 3.9592e-07 23.1509 0.104196Z"
        fill="#A4FECB"
      />
      <path
        d="M69.8947 58.1421L69.6842 58.5589C69.5789 58.7673 69.5789 58.8715 69.6842 59.0799L69.8947 59.4967C70 59.705 70 60.1218 69.6842 60.226C69.5789 60.3302 69.4736 60.3302 69.3684 60.3302H68.842C68.6314 60.3302 68.5261 60.4344 68.4208 60.5386L68.105 60.9554C67.8945 61.1638 67.5786 61.1638 67.3681 61.0596C67.2628 60.9554 67.2628 60.8512 67.1575 60.747L67.0522 60.226C67.0522 60.0176 66.947 59.9134 66.7364 59.8092L66.3153 59.6008C65.9994 59.4967 65.8942 59.1841 65.9994 58.8715C65.9994 58.7673 66.1047 58.6631 66.21 58.6631L66.6311 58.4547C66.7364 58.3505 66.8417 58.2463 66.9469 58.0379L67.0522 57.5169C67.0522 57.2044 67.3681 56.996 67.6839 56.996C67.7892 56.996 67.8945 57.1002 67.9997 57.1002L68.3156 57.4128C68.4208 57.5169 68.6314 57.6211 68.842 57.5169L69.3684 57.4128C69.6842 57.3086 69.8947 57.517 70 57.8295C70 57.9337 70 58.0379 69.8947 58.1421Z"
        fill="#A4FECB"
      />
    </svg>
  );
};

export default SuccessCheck;
