export const RemoveIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 6.875H11.25C11.5952 6.875 11.875 7.15482 11.875 7.5C11.875 7.84518 11.5952 8.125 11.25 8.125H3.75C3.40482 8.125 3.125 7.84518 3.125 7.5C3.125 7.15482 3.40482 6.875 3.75 6.875Z"
        fill="#5433FF"
      />
    </svg>
  );
};
