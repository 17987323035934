import React, { useEffect } from "react";
import { useState } from "react";
import payoutService from "api/payoutService";
import { PayoutProvider } from "@nestcoinco/onboard-api-gateway-api-client";
import toastNotificationService, { ToastTypeEnum } from "services/toastNotificationService";
import SelectProvider from "../../modules/accounts/SelectProvider";
import SelectCurrency from "modules/accounts/SelectCurrency";


const ConnectProvider = () => {
  const [connectAccountStage, setConnectAccountStage] = useState<1|2>(1);
  const [selectedCurrency, setSelectedCurrency] = useState<string>();
  const [currencies, setCurrencies] = useState<string[]>([]);
  const [providers, setProviders] = useState<Array<PayoutProvider>>([]);

  const getAllCurrencies = (payoutProviders: Array<PayoutProvider>) => {
    let allCurrencies: string[] = [];
    let currencyExist: {[currency: string]: boolean} = {}
    for (let payoutProvider of payoutProviders) {
      payoutProvider.supportedCurrencies.forEach((currency) => {
        if (!currencyExist[currency]) {
          currencyExist[currency] = true;
          allCurrencies.push(currency);
        }
      })
    }
    return allCurrencies;

  }

  useEffect(() => {
    payoutService.getProviders().then((providers) => {
      setProviders(providers)
      setCurrencies(getAllCurrencies(providers))
    }).catch((err) => {
      toastNotificationService.alert('Error occur getting providers', ToastTypeEnum.ERROR);
    })
  }, [])


  switch (connectAccountStage) {
    case 1: 
      return (<SelectCurrency 
        currencies={currencies}
        selectedCurrency={selectedCurrency!}
        setSelectedCurrency={setSelectedCurrency}
        next={() => {
          setConnectAccountStage(2)
        }}
      />)

    case 2: 
      return (
        <SelectProvider
          payoutProviders={providers.filter((provider) => {
            return provider.supportedCurrencies.includes(selectedCurrency!)
          })}
        />
      )


    default:
      return <>Please refresh the page</>

  }

};


export default ConnectProvider;
