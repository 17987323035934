import payoutsService from "api/payoutService";
import { routes } from "config/routes";
import Button, { LoadingButton } from "modules/common/components/Button/Button";
import Modal from "modules/common/components/Modal/Modal";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toastNotificationService, {
  ToastTypeEnum,
} from "services/toastNotificationService";

const OnDisconnectModal = ({
  closeModal,
  isOpen,
  hasOpenAds,
  accountId,
  dataTestId,
}: {
  closeModal: () => void;
  isOpen: boolean;
  hasOpenAds: boolean;
  accountId: string;
  dataTestId?: string;
}) => {
  const navigate = useNavigate();
  const { accounts } = routes;
  const [isLoading, setIsLoding] = useState(false);

  async function handleClick() {
    try {
      setIsLoding(true);
      await payoutsService.disconnectAccount(accountId);
      toastNotificationService.alert(
        "Account disconnected",
        ToastTypeEnum.SUCCESS
      );
      navigate(accounts.index);
    } catch (err) {
      toastNotificationService.alert(
        "There was an error disconneting your account",
        ToastTypeEnum.ERROR
      );
    } finally {
      setIsLoding(false);
      closeModal();
    }
  }
  return (
    <Modal closeModal={closeModal} isOpen={isOpen} dataTestId={dataTestId}>
      {hasOpenAds ? (
        <p className="text-center" data-testid="open-ads-message">
          You have open ads and ongoing orders, please ensure all orders are
          completed and ads deactivated before proceeding{" "}
        </p>
      ) : (
        <>
          <p className="text-center">
            This payment method will no longer be available for new orders. Do
            you want to proceed?
          </p>
          <div className="flex justify-between mt-8">
            <LoadingButton
              dataTestId="disconnect-ok"
              onClick={handleClick}
              loading={isLoading}
              className="btn-danger py-3 basis-[100px]"
            >
              Yes
            </LoadingButton>
            <Button
              className="bg-primary-200 text-primary py-3 basis-[100px]"
              onClick={closeModal}
            >
              No
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default OnDisconnectModal;
