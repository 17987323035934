import {
  OrderQueryStatus,
  OrdersSvcOrderResponse,
  OrderTransactionList,
} from "@nestcoinco/onboard-api-gateway-api-client";
import ordersService from "api/ordersService";
import Button from "modules/common/components/Button/Button";
import DropDown from "modules/common/components/Dropdown/DropDown";
import ChevronDown from "modules/common/components/Icons/ChevronDown";
import Pagination from "modules/common/components/Pagination/Pagination";
import Order from "modules/orders/Order";
import OrdersTable from "modules/orders/OrdersTable";
import { useEffect, useState } from "react";
import toastNotificationService, {
  ToastTypeEnum,
} from "services/toastNotificationService";

const orderStatusMap = {
  [OrderQueryStatus.FAILED]: "Failed",
  [OrderQueryStatus.COMPLETED]: "Completed",
  [OrderQueryStatus.ONGOING]: "Pending",
};

const OrderHistory = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [orderStat, setOrderStat] = useState({
    completed: 0,
    failed: 0,
    ongoing: 0,
  });
  const [pageSize, setPageSize] = useState<number>(10);
  const [orderLoading, setOrderloading] = useState(true);
  const [orderData, setOrderData] = useState<OrdersSvcOrderResponse>();
  const [statusFilter, setStatusFilter] = useState<OrderQueryStatus | null>();
  const [orders, setOrders] = useState<OrderTransactionList>({
    name: "",
    records: [],
    nextPage: 1,
    previousPage: undefined,
    size: 10,
    totalItems: 0,
  });

  useEffect(() => {
    const getStat = async () => {
      const { totalItems: completed } = await ordersService.getOrders({
        page: currentPage,
        size: pageSize,
        orderStatus: OrderQueryStatus.COMPLETED,
      });
      const { totalItems: ongoing } = await ordersService.getOrders({
        page: currentPage,
        size: pageSize,
        orderStatus: OrderQueryStatus.ONGOING,
      });
      const { totalItems: failed } = await ordersService.getOrders({
        page: currentPage,
        size: pageSize,
        orderStatus: OrderQueryStatus.FAILED,
      });

      setOrderStat({
        completed: completed || 0,
        ongoing: ongoing || 0,
        failed: failed || 0,
      });
    };

    getStat();
  }, []);

  useEffect(() => {
    setOrderloading(true);
    const payload: {
      orderStatus?: OrderQueryStatus;
      page: number;
      size: number;
      accountId?: string;
    } = {
      page: currentPage,
      size: pageSize,
    };
    if (statusFilter) {
      payload.orderStatus = statusFilter;
    }
    ordersService
      .getOrders(payload)
      .then((data) => {
        setOrders(data as any);
      })
      .catch((error) => {
        toastNotificationService.alert(
          "Error fetching orders",
          ToastTypeEnum.ERROR,
          "fetchOrderError"
        );
      })
      .finally(() => {
        setOrderloading(false);
      });
  }, [statusFilter, currentPage, pageSize]);

  return orderData ? (
    <Order data={orderData} setOrder={setOrderData} />
  ) : (
    <div>
      <section className="bg-white rounded-[10px] p-6 py-10 mb-16">
        <div className="flex justify-center gap-10 pt-6 pb-6">
          <div className="flex basis-[200px] flex-col gap-2 items-center border border-green-300 p-6 rounded-md text-green-900">
            <span className="text-xs text-green-900">Completed</span>
            <span className="text-4xl font-bold ">{orderStat.completed}</span>
          </div>
          <div className="flex basis-[200px] flex-col gap-2 items-center border border-gray-100 p-6 rounded-md">
            <span className="text-xs">Ongoing</span>
            <span className="text-4xl font-bold">{orderStat.ongoing}</span>
          </div>
          <div className="flex basis-[200px] flex-col gap-2 items-center border border-error-100 p-6 rounded-md text-error">
            <span className="text-xs">Failed</span>
            <span className="text-4xl font-bold text-error">
              {orderStat.failed}
            </span>
          </div>
        </div>
      </section>

      <div className="flex items-baseline gap-4 mb-6 p-4 px-6 rounded-md bg-white">
        <p className="mr-auto font-bold text-[16px]">Orders History</p>
        <DropDown
          items={[
            {
              label: "All",
              onClick: () => {
                setStatusFilter(null);
              },
            },
            {
              label: orderStatusMap[OrderQueryStatus.FAILED],
              onClick: () => {
                setStatusFilter(OrderQueryStatus.FAILED);
              },
            },
            {
              label: orderStatusMap[OrderQueryStatus.COMPLETED],
              onClick: () => {
                setStatusFilter(OrderQueryStatus.COMPLETED);
              },
            },
            {
              label: orderStatusMap[OrderQueryStatus.ONGOING],
              onClick: () => {
                setStatusFilter(OrderQueryStatus.ONGOING);
              },
            },
          ]}
          placeholder={
            <Button className="flex justify-between border border-gray-300 py-2 w-[120px] font-normal">
              {statusFilter ? orderStatusMap[statusFilter] : "Status"}{" "}
              <ChevronDown />
            </Button>
          }
        />
      </div>
      <OrdersTable
        data={orders.records}
        setOrder={setOrderData}
        loading={orderLoading}
      />

      <div className="py-6">
        <Pagination
          size={pageSize}
          totalItems={orders.totalItems}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default OrderHistory;
