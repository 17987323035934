import APIServices, { createClientInstance } from ".";
import { MerchantDto, AccountsMerchantClient } from '@nestcoinco/onboard-api-gateway-api-client';

class AccountService extends APIServices {
  private merchantClient: AccountsMerchantClient;

  constructor() {
    super();
    this.merchantClient = createClientInstance(AccountsMerchantClient);
  }

  async getMyDetails(): Promise<MerchantDto> {
    try {
      const { data } = await this.merchantClient.getMerchant({
        headers: {
          ...this.headers,
          "x-auth-token": this.getToken(),
        },
      });
      return data;
    } catch (error: any) {
      await this.responseHandler(error.response);
      return Promise.reject(error);
    }
  }
}

export default new AccountService();
