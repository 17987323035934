import Button from "modules/common/components/Button/Button";
import {
  selectedToken,
  selectedNetwork,
} from "modules/withdrawal/api/externalAddressAtoms";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import classNames from "classnames";

const SelectNetwork = () => {
  const navigate = useNavigate();
  const token = useAtom(selectedToken)[0];
  const setNetworkType = useAtom(selectedNetwork)[1];

  if (!token) {
    navigate("/withdrawal");
  }

  function handleClick(network: any) {
    setNetworkType(network);
    navigate("/withdrawal/complete-withdrawal");
  }

  return (
    <div className="mx-auto w-[450px] mt-14 bg-white p-10 rounded-[10px]">
      <h1 className="text-center font-semibold pb-3">Select Network</h1>
      <div className="flex flex-col gap-3">
        {token?.networks.map((network: any) => {
          const {
            networkInfo: { name, alias },
          } = network;
          const disabled = !network.withdrawalAvailable;
          return (
            <Button
              key={network.code}
              onClick={() => handleClick(network)}
              dataTestId={name.split(" ").join("-").toLowerCase()}
              className={classNames(
                "height-[150px] flex items-baseline gap-1 justify-center rounded-md  text-primary-300 border border-primary-300",
                { "border-none": disabled }
              )}
              disabled={disabled}
            >
              {name}{" "}
              <span className="text-xs font-normal">
                ({alias}) - Fee ({network.withdrawalFee} {token.name})
              </span>
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default SelectNetwork;
