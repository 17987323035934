import { range } from "modules/common/utils";

export const usePagination = ({ totalCount, pageSize, currentPage }: any) => {
  let paginationRange = [];
  const totalPageCount = Math.ceil(totalCount / pageSize);

  // Pages count is determined as total number of numbers we want to show including the dots
  const totalPageNumbers = 5;

  if (totalPageNumbers >= totalPageCount) {
    paginationRange = range(1, totalPageCount);
  } else if (currentPage >= 2 && currentPage < totalPageCount - 2) {
    paginationRange = [
      currentPage,
      currentPage + 1,
      "DOTS",
      totalPageCount - 1,
      totalPageCount,
    ];
  } else if (currentPage === 1) {
    paginationRange = [
      1,
      currentPage + 1,
      "DOTS",
      totalPageCount - 1,
      totalPageCount,
    ];
  } else if (currentPage === totalPageCount) {
    paginationRange = [1, 2, "DOTS", totalPageCount - 1, totalPageCount];
  } else if (currentPage >= 2 && currentPage === totalPageCount - 2) {
    paginationRange = [
      currentPage - 1,
      currentPage,
      "DOTS",
      totalPageCount - 1,
      totalPageCount,
    ];
  } else {
    paginationRange = [
      currentPage - 2,
      currentPage - 1,
      "DOTS",
      currentPage,
      totalPageCount,
    ];
  }

  return paginationRange;
};
