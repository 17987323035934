import {
  PayoutsSvcAccountBalance,
  AssetAmount,
} from "@nestcoinco/onboard-api-gateway-api-client";
import payoutService from "api/payoutService";
import ordersService from "api/ordersService";
import OnDisconnectModal from "modules/accounts/OnDisconnectModal";
import BackButton from "modules/common/components/Button/BackButton";
import Button from "modules/common/components/Button/Button";
import Table from "modules/common/components/Table";
import { convertDateToWords } from "modules/common/utils/dateFormatter";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { hasOpenAds } from "modules/accounts/api/hasOpenAds";
import { OrderQueryStatus } from "@nestcoinco/onboard-api-gateway-api-client";
import Loader from "modules/common/components/Icons/Loader";
import { currencyFormatter } from "utils/common";
import TableBodyRow from "modules/common/components/Table/TableBodyRow";
import ErrorContainer from "modules/common/components/ErrorContainer/ErrorContainer";
import { formatPayoutMethod } from "modules/common/utils/enumFormatters";

function AccountId() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const params = useParams();
  const {
    data: account,
    isLoading,
    error,
  } = useQuery("getSingleAccount", () =>
    payoutService.getSingleAccount(params.accountId!)
  );
  const navigate = useNavigate();

  const { data: order } = useQuery("getOrders", () =>
    ordersService.getOrders({
      orderStatus: OrderQueryStatus.COMPLETED,
      accountId: params?.accountId!,
      page: 1,
      size: 1,
    })
  );
  const { data: adsData } = useQuery("getPaymentAccountAds", () =>
    ordersService.getPaymentAccountAds(params?.accountId!)
  );
  const connectedAds = adsData?.ads || [];

  function closeModal() {
    setIsModalOpen(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  if (error) {
    return (
      <ErrorContainer
        errorTitle="500"
        errorMessage="Error fetching accounts data"
      />
    );
  }

  return (
    <>
      <div className="max-w-[500px] mx-auto pb-8">
        <BackButton />
        <div className="py-3" />
        {isLoading ? (
          <div className="flex justify-center">
            <Loader stroke="black" />{" "}
          </div>
        ) : (
          <div className="border border-gray-100 rounded-md p-6 bg-white">
            <h1 className="font-semibold text-xl text-center mb-6">
              Payment Account Details
            </h1>
            <ul className="mb-12">
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Date Connected
                </span>
                {convertDateToWords(account?.createdAt)}
              </li>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Last payout
                </span>
                {order?.records.length
                  ? convertDateToWords(order?.records[0].createdAt)
                  : "No payouts yet"}
              </li>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Provider
                </span>
                {account?.providerDetails?.name}
              </li>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Account Name
                </span>
                {account?.accountName}
              </li>
              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  status
                </span>
                {((status?: string) => {
                  const active = status === "ACTIVE";
                  return active ? "Active" : "Inactive";
                })(account?.status)}
              </li>

              <li className="flex gap-6 py-2">
                <span className="font-semibold basis-40 inline-block">
                  Channels
                </span>
                {formatPayoutMethod(
                  account?.providerDetails?.supportedPaymentChannels
                )?.join(",")}
              </li>
            </ul>
            <h2 className="text-center text-[18px] font-semibold mb-2">
              Daily Limits
            </h2>
            <Table headers={["Currency", "Limit"]}>
              {account?.dailyLimits?.map((limit: AssetAmount) => {
                return (
                  <TableBodyRow
                    data={[limit.asset, currencyFormatter(limit.amount, limit.asset)]}
                    key={limit.asset}
                  />
                );
              })}
            </Table>
            <h2 className="text-center text-lg font-semibold mb-2 mt-12">
              Balance(s)
            </h2>
            <Table headers={["Currency", "Balance", "Last Update"]}>
              {account?.accountBalances?.map(
                (item: PayoutsSvcAccountBalance, index: number) => {
                  return (
                    <TableBodyRow
                      data={[
                        item.asset,
                        currencyFormatter(item.amount),
                        convertDateToWords(item.lastUpdatedAt),
                      ]}
                      key={index}
                    />
                  );
                }
              )}
            </Table>
            <h2 className="text-center text-lg font-semibold mt-12 mb-2">
              Connected Ads
            </h2>
            {connectedAds.length && account?.status === "ACTIVE" ? (
              connectedAds.map((ad: any) => {
                return (
                  <div
                    className="flex justify-between items-center border rounded-md p-4 mb-4"
                    key={ad?.id}
                  >
                    <p>
                      Buy {ad?.payoutCurrency} at {ad.rate}{" "}
                      <span className="block text-sm">
                        (limit {ad.minimumAmount} - {ad.maximumAmount})
                      </span>
                    </p>
                    <button
                      className="border border-green-700 text-green-700 cursor-pointer px-4 py-1 rounded-md"
                      onClick={() =>
                        navigate(`/ads/edit/${ad.id}`, {
                          state: {
                            ad,
                          },
                        })
                      }
                    >
                      Edit
                    </button>
                  </div>
                );
              })
            ) : (
              <div className="grid place-items-center h-20 rounded-md bg-primary-100">
                No connected ads
              </div>
            )}
            {account?.status === "ACTIVE" && !connectedAds.length ? (
              <div className="flex justify-end mt-6">
                <NavLink
                  to="/ads/create"
                  className="border border-primary text-primary p-5 py-2 rounded-md"
                >
                  New Ad
                </NavLink>
              </div>
            ) : null}
          </div>
        )}
        {account?.status === "ACTIVE" && (
          <div className="mb-4 flex justify-end items-center mt-4">
            <Button
              dataTestId="disconnect-account"
              onClick={openModal}
              className="btn-danger py-3"
            >
              Disconnect
            </Button>
          </div>
        )}
      </div>
      <OnDisconnectModal
        dataTestId="disconnect-modal"
        isOpen={isModalOpen}
        closeModal={closeModal}
        hasOpenAds={hasOpenAds(connectedAds)}
        accountId={account?.accountId!}
      />
    </>
  );
}

export default AccountId;
