export function calculateCashBalances(accounts: any) {
  const accountsMap: any = {};
  accounts.forEach((account: any) => {
    account.accountBalances.forEach(
      (acctBalance: { asset: string; amount: number }) => {
        const currencyName = acctBalance.asset;
        if (accountsMap[currencyName]) {
          accountsMap[currencyName] = {
            amount: accountsMap[currencyName]?.amount + acctBalance.amount,
            count: accountsMap[currencyName]?.count + 1,
          };
        } else {
          accountsMap[currencyName] = {
            amount: acctBalance.amount,
            count: 1,
          };
        }
      }
    );
  });
  return accountsMap;
}
