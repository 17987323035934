import { AdDto } from "@nestcoinco/onboard-api-gateway-api-client";
import ordersService from "api/ordersService";
import Button, { LoadingButton } from "modules/common/components/Button/Button";
import DropDown from "modules/common/components/Dropdown/DropDown";
import ErrorContainer from "modules/common/components/ErrorContainer/ErrorContainer";
import ChevronDown from "modules/common/components/Icons/ChevronDown";
import Loader from "modules/common/components/Icons/Loader";
import Modal from "modules/common/components/Modal/Modal";
import Table from "modules/common/components/Table";
import NoTableData from "modules/common/components/Table/NoTableData";
import TableBodyRow from "modules/common/components/Table/TableBodyRow";
import { formatPayoutMethod } from "modules/common/utils/enumFormatters";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import toastNotificationService, {
  ToastTypeEnum,
} from "services/toastNotificationService";

const adsTableHeadData = [
  "Type",
  "Fiat",
  "status",
  "Rate",
  "Order limit",
  "Payout currency",
  "Account",
  "Method",
  "Action",
];

const behaviourMap = {
  FIXED: "Fixed",
  FLEXIBLE: "Flexible",
};

const AdsTable = () => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);
  const [deleteId, setDeleteId] = React.useState<string>("");
  const navigate = useNavigate();

  const {
    data: ads,
    refetch,
    isLoading,
    error,
  } = useQuery("getAds", () => ordersService.getAds());

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModal = (id: string) => {
    setDeleteId(id);
    setOpenModal(true);
  };

  const handleDelete = (id: string) => {
    setDeleteLoading(true);
    return ordersService
      .deleteAd(id)
      .then((data) => {
        toastNotificationService.alert("Ad deleted.", ToastTypeEnum.SUCCESS);
      })
      .catch((err) => {
        toastNotificationService.alert(
          "Error deleting ads.",
          ToastTypeEnum.ERROR
        );
      })
      .finally(() => {
        refetch();
        setOpenModal(false);
        setDeleteLoading(false);
      });
  };

  const handleDisableState = (id: string, disabled: boolean) => {
    return (
      disabled
        ? ordersService
            .enableAd(id)
            .then((data) => {
              toastNotificationService.alert(
                "Ad enabled.",
                ToastTypeEnum.SUCCESS
              );
            })
            .catch((err) => {
              toastNotificationService.alert(
                "Error enabling ads.",
                ToastTypeEnum.ERROR
              );
            })
        : ordersService
            .disableAd(id)
            .then((data) => {
              toastNotificationService.alert(
                "Ad disabled.",
                ToastTypeEnum.SUCCESS
              );
            })
            .catch((err) => {
              toastNotificationService.alert(
                "Error disabling ads.",
                ToastTypeEnum.ERROR
              );
            })
    ).finally(() => {
      refetch();
    });
  };

  if (error) {
    return (
      <ErrorContainer errorTitle="500" errorMessage="Error fetching Ads data" />
    );
  }

  return isLoading ? (
    <div className="flex justify-center w-full">
      <Loader stroke="black" />{" "}
    </div>
  ) : (
    <Table headers={adsTableHeadData} dataTestId="ads-table">
      {ads?.ads?.length ? (
        ads?.ads?.map((item: AdDto) => {
          const {
            id,
            paymentAccountName,
            payoutCurrency,
            supportedPaymentChannels,
            rate,
            minimumAmount,
            maximumAmount,
            disabled,
            behaviour,
          } = item;
          let rowData = [
            behaviourMap[behaviour!],
            "USD",
            ((disabled: boolean) => {
              return (
                <p className={`text-${disabled ? "error" : "green-700"}`}>
                  {disabled ? "Inactive" : "Active"}
                </p>
              );
            })(disabled!),
            rate,
            `${payoutCurrency} ${minimumAmount} - ${payoutCurrency} ${maximumAmount}`,
            payoutCurrency,
            paymentAccountName,
            formatPayoutMethod(supportedPaymentChannels)?.map((method) => {
              return (
                <span className="border px-1 py-px rounded-md">{method}</span>
              );
            }),

            <DropDown
              items={[
                ((disabled: boolean) => {
                  return disabled
                    ? null
                    : {
                        label: "Edit",
                        onClick: () =>
                          navigate(`/ads/edit/${id}`, {
                            state: {
                              ad: item,
                            },
                          }),
                        className: "w-full text-primary",
                      };
                })(disabled!) as any,
                {
                  label: disabled ? "Enable" : "Disable",
                  onClick: () => handleDisableState(id!, disabled!),
                  className: `w-full text-yellow-700 ${
                    disabled ? "!text-green-700" : ""
                  }`,
                },
                {
                  label: "Delete",
                  onClick: () => {
                    handleOpenModal(id!);
                  },
                  className: "w-full text-error",
                },
              ].filter((item) => item)}
              placeholder={
                <span className="flex items-center align-middle text-primary op">
                  More
                  <span className="translate-y-0.5 ">
                    <ChevronDown color="#5433FF" />
                  </span>
                </span>
              }
            />,
          ];
          return <TableBodyRow data={rowData} key={id} />;
        })
      ) : (
        <NoTableData message="No ads data" />
      )}
      <Modal isOpen={openModal} closeModal={handleCloseModal} title="Delete Ad">
        <div className="h-8 flex justify-center items-center">
          Do you want to delete this ad?
        </div>
        <div className="flex justify-evenly">
          <Button
            className="border text-primary p-2 w-20"
            onClick={handleCloseModal}
            dataTestId="cancel-ads-delete-btn"
          >
            No
          </Button>
          <LoadingButton
            loading={deleteLoading}
            className="border text-error p-2 w-20 opacity"
            onClick={() => handleDelete(deleteId)}
            dataTestId="delete-ads-btn"
          >
            Yes
          </LoadingButton>
        </div>
      </Modal>
    </Table>
  );
};

export default AdsTable;
