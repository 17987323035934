import { toast } from 'react-toastify';

export enum ToastTypeEnum {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARN = 'WARN',
  INFO = 'INFO'
}

class ToastNotification {
  clearAll() {
    toast.dismiss()
  }
  clear(id: string) {
    toast.dismiss(id);
  }
  alert(message: string, type?: ToastTypeEnum, customId?: string) {
    switch (type) {
      case ToastTypeEnum.SUCCESS:
        return toast.success(message, {
          toastId: customId
        });
      case ToastTypeEnum.ERROR:
        return toast.error(message, {
          toastId: customId
        });
      case ToastTypeEnum.WARN:
        return toast.warn(message, {
          toastId: customId
        });
      case ToastTypeEnum.INFO:
        return toast.info(message, {
          toastId: customId
        });
      default:
        return toast(message)
    }
  }
}

export default new ToastNotification()
