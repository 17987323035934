import classNames from "classnames";
import React, { ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";

interface NavLinkprops {
  children: ReactNode;
  to: string;
  className?: string;
  dataTestId?: string;
}

const CustomNavLink = ({ children, to, className, dataTestId }: NavLinkprops) => {
  const location = useLocation();
  const isActive = location.pathname.includes(to);
  return (
    <NavLink
      to={to}
      className={classNames(
        `text-sm hover:text-primary-300 transition-colors ${className}`,
        { "text-primary-300 font-semibold": isActive }
      )}
      data-testid={dataTestId}
    >
      {children}
    </NavLink>
  );
};

export default CustomNavLink;
