const accountsprefix = "/accounts";

export const routes = {
  home: "/",
  accounts: {
    index: accountsprefix,
    provider: accountsprefix + "/select-provider",
    currency: accountsprefix + "/select-currency",
    connect: accountsprefix + "/connect",
    connectWithCreds: accountsprefix + "/connect-with-creds",
    connectComplete: accountsprefix + "/connect-complete",
  },

  dashboard: {
    index: "/dashboard",
  },
};
