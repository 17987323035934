import classNames from "classnames";
import Button from "modules/common/components/Button/Button";
import { selectedToken, selectedAsset } from "modules/withdrawal/api/externalAddressAtoms";
import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import walletService from "api/walletService";
import { useQuery } from "react-query";
import ErrorContainer from "modules/common/components/ErrorContainer/ErrorContainer";
import Loader from "modules/common/components/Icons/Loader";

const SelectStableCoin = () => {
  const navigate = useNavigate();
  const setSelectedToken = useAtom(selectedToken)[1];
  const setSelectedAsset = useAtom(selectedAsset)[1];
  const { isLoading, error, data } = useQuery("networkTokens", () =>
    walletService.getNetworkTokens()
  );

  useEffect(() => {
    if (data?.asset) {
      setSelectedAsset(data.asset);
    }
  }, [data, setSelectedAsset]);

  if (error) {
    return (
      <ErrorContainer
        errorTitle="500"
        errorMessage="Error fetching Network tokens"
      />
    );
  }

  return (
    <div className="mx-auto w-[450px] mt-14 bg-white p-10 rounded-[10px]">
      <h1 className="text-center font-semibold pb-6">Select stable coin</h1>
      <div className="flex flex-col gap-3 rounded-md">
        {isLoading ? (
          <div className="flex justify-center">
            <Loader stroke="black"/>{" "}
          </div>
        ) : (
          data?.tokens?.map((token: any) => {
            const { symbol, supportedNetworks } = token;
            return (
              <Button
                key={token.name}
                dataTestId={symbol.toLowerCase()}
                onClick={() => {
                  setSelectedToken({
                    name: symbol,
                    networks: supportedNetworks,
                  });
                  navigate("/withdrawal/select-network");
                }}
                className={classNames(
                  "height-[150px] grid place-items-center rounded-md",
                  {
                    "text-primary-300 border border-primary-300": true,
                  }
                )}
              >
                {symbol}
              </Button>
            );
          })
        )}
      </div>
    </div>
  );
};

export default SelectStableCoin;
