import React from "react";

type TableHeadProps = {
  data: string[]
}
const TableHead = ({
  data
}: TableHeadProps) => {
  return <div className={`grid custom-grid-cols-${data.length} gap-4 w-full text-center border-grey-50 border-b text-[14px] pb-2.5 font-bold`}>
    {data.map((td) => {
      return <h5>{td}</h5>
    })}
  </div>
}

export default TableHead;