import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import Login from "pages/Auth/Login";
import EnterOTP from "pages/Auth/Otp";
import Dashboard from "pages/Dashboard";
import DefaultLayout from "modules/common/layouts/DefaultLayout";
import WithdrawalMethod from "pages/Withdrawal/WithdrawalMethod";
import SelectStableCoin from "pages/Withdrawal/SelectStableCoin";
import SelectNetwork from "pages/Withdrawal/SelectNetwork";
import CompleteWithdrawal from "pages/Withdrawal/CompleteWithdrawal";
import ConfirmWithdrawal from "pages/Withdrawal/ConfirmWithdrawal";
import WithdrawalSuccess from "pages/Withdrawal/WithdrawalSuccess";
import TransactionHistory from "pages/Transaction/TransactionHistory";
import OrderHistory from "pages/Order/OrderHistory";
import OrderId from "pages/Order/OrderId";
import WithdrawalLayout from "modules/withdrawal/layouts/WithdrawalLayout";
import Ads from "pages/Ads";
import CreateAds from "pages/Ads/CreateAds";
import EditAd from "pages/Ads/EditAd";
import Accounts from "pages/Accounts/Accounts";
import AccountId from "pages/Accounts/AccountId";
import ConnectAccountWithOTP from "pages/Accounts/ConnectAccountWithOTP";
import ConnectAccountComplete from "pages/Accounts/ConnectAccountComplete";
import ConnectProvider from "pages/Accounts/ConnectProvider";
import ConnectAccountWithOAuth from "pages/Accounts/ConnectAccountWithOAuth";
import ProtectedRoute from "modules/common/components/Protected/ProtectedRoute";
import NotFound from "pages/NotFound/NotFound";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<DefaultLayout />}>
      <Route index element={<Navigate to="auth/login" />} />

      <Route path="auth" element={<ProtectedRoute />}>
        <Route path="login" element={<Login />} />
        <Route path="otp" element={<EnterOTP />} />
      </Route>

      <Route
        path="dashboard"
        element={<ProtectedRoute element={Dashboard} />}
      />

      <Route
        path="withdrawal"
        element={<ProtectedRoute element={WithdrawalLayout} />}
      >
        <Route index element={<WithdrawalMethod />} />
        <Route path="select-stable-coin" element={<SelectStableCoin />} />
        <Route path="select-network" element={<SelectNetwork />} />
        <Route path="complete-withdrawal" element={<CompleteWithdrawal />} />
        <Route path="confirm" element={<ConfirmWithdrawal />} />
        <Route path="success" element={<WithdrawalSuccess />} />
      </Route>

      <Route path="transactions" element={<ProtectedRoute />}>
        <Route index element={<TransactionHistory />} />
      </Route>

      <Route path="orders" element={<ProtectedRoute />}>
        <Route index element={<OrderHistory />} />
        <Route path=":transactionId" element={<OrderId />} />
      </Route>

      <Route path="ads" element={<ProtectedRoute />}>
        <Route index element={<Ads />} />
        <Route path="create" element={<CreateAds />} />
        <Route path="edit/:adId" element={<EditAd />} />
      </Route>

      <Route path="accounts" element={<ProtectedRoute />}>
        <Route index element={<Accounts />} />
        <Route path="connect" element={<ConnectProvider />} />
        <Route path="connect-with-creds" element={<ConnectAccountWithOTP />} />
        <Route
          path="connect-with-oauth"
          element={<ConnectAccountWithOAuth />}
        />
        <Route path="connect-complete" element={<ConnectAccountComplete />} />
        <Route path=":accountId" element={<AccountId />} />
      </Route>

      <Route path="/*" element={<NotFound />} />
    </Route>
  )
);
