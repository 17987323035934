import { routes } from "config/routes";
import { useAtom } from "jotai";
import { userAtom } from "modules/common/api/userAtom";
import React from "react";
import { NavLink } from "react-router-dom";
import CustomNavLink from "../CustomLink/CustomNavLink";
import ProfileDropdown from "../Dropdown/ProfileDropdown";
import { OnboardLogo } from "../Icons/OnboardLogo";
import MaxWidthContainer from "../MaxWidthContainer/MaxWidthContainer";

const Header = () => {
  const userData = useAtom(userAtom)[0];

  return (
    <header className="bg-white mb-6">
      <MaxWidthContainer className="py-6 mb-3 flex justify-between items-center gap-3">
        <NavLink className="flex-1" to="/">
          <OnboardLogo />
        </NavLink>
        {userData && (
          <>
            <div className="flex items-center gap-8">
              <CustomNavLink to="/transactions">Fast Wallet</CustomNavLink>
              <CustomNavLink to="/ads">Ads</CustomNavLink>
              <CustomNavLink to="/orders" dataTestId="orders-nav-tab">
                Orders
              </CustomNavLink>
              <CustomNavLink to="/accounts">Accounts</CustomNavLink>
            </div>
            <div className="flex items-center justify-end gap-5 flex-1">
              <NavLink
                className="font-semibold text-sm bg-primary-100 text-primary py-2 px-3 rounded-[10px]"
                to={routes.accounts.connect}
              >
                Add Account
              </NavLink>
              <ProfileDropdown />
            </div>
          </>
        )}
      </MaxWidthContainer>
    </header>
  );
};

export default Header;
