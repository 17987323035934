import walletService from "api/walletService";
import { getTotalBalances } from "modules/dashboard/api/getTotalBalances";
import React from "react";
import { useQuery } from "react-query";
import toastNotificationService, {
  ToastTypeEnum,
} from "services/toastNotificationService";
import Loader from "../Icons/Loader";
import WalletCard from "./WalletCard";

const WalletCardsContainer = () => {
  const {
    data: wallets,
    isLoading,
    error,
  } = useQuery("getWallets", () => walletService.getWallets());
  const assets = getTotalBalances(wallets! || []);

  if (error) {
    toastNotificationService.alert(
      "Cannot fetch wallets data",
      ToastTypeEnum.ERROR
    );
  }

  return (
    <div className="flex justify-center overflow-auto">
      {isLoading ? (
        <div className="flex justify-center">
          <Loader stroke="black" />
        </div>
      ) : (
        wallets &&
        Object.keys(assets).map((asset, index) => {
          return (
            <WalletCard
              dataTestId={`wallet-card-${index}`}
              currency={asset}
              asset={assets[asset]}
            />
          );
        })
      )}
    </div>
  );
};

export default WalletCardsContainer;
