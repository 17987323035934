import {
  PayoutProvider,
  ProviderSetupType,
} from "@nestcoinco/onboard-api-gateway-api-client";
import { routes } from "config/routes";
import Button from "modules/common/components/Button/Button";
import React, { PropsWithChildren } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toastNotificationService, {
  ToastTypeEnum,
} from "services/toastNotificationService";

type SelectProviderProps = PropsWithChildren & {
  payoutProviders: Array<PayoutProvider>;
};

const SelectProvider = ({ payoutProviders }: SelectProviderProps) => {
  const navigate = useNavigate();
  const { accounts } = routes;

  const handleConnectProvider = (provider: PayoutProvider) => {
    switch (provider.setupRequirement.setupType) {
      case ProviderSetupType.USERNAME_PASSWORD:
        return navigate(accounts.connectWithCreds, {
          state: {
            provider,
          },
        });

      case ProviderSetupType.API_KEY:
        return navigate(accounts.connectWithCreds, {
          state: {
            provider,
          },
        });

      case ProviderSetupType.OAUTH2:
        localStorage.setItem(
          "provider",
          JSON.stringify({ id: provider.id, name: provider.name })
        );
        return window.location.replace(
          provider.setupRequirement.authorizationUri!
        );

      default:
        toastNotificationService.alert(
          "Payout provider not configured",
          ToastTypeEnum.ERROR
        );
    }
  };

  return (
    <div className="mx-auto w-[450px] mt-14 bg-white rounded-[10px] p-10">
      <h1 className="text-center font-semibold pb-4">
        Select payments provider
      </h1>
      <div className="flex flex-col gap-3">
        {payoutProviders.map((payoutProvider) => {
          return (
            <Button
              onClick={() => handleConnectProvider(payoutProvider)}
              className="height-[150px] grid place-items-center rounded-md  text-primary-300 border border-primary-300"
              dataTestId="select-provider-name"
            >
              {payoutProvider.name}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default SelectProvider;
