import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Tick } from "assets/svgs/tick-circle.svg";
import Button from "modules/common/components/Button/Button";
import { useEffect } from "react";
import { routes } from "config/routes";

const ConnectAccountComplete = () => {
  const { accounts, dashboard } = routes;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location?.state?.providerName) {
      return navigate(accounts.index);
    }
  }, []);

  return (
    <div className="mx-auto w-[550px] mt-14 flex flex-col items-center bg-white p-10 rounded-[10px]">
      <Tick className="w-40 fill-primary mb-8" />
      Your {location?.state?.providerName} Account has been successfully added
      <div className="flex w-full justify-between mt-14">
        <Button
          className="py-3 rounded-md  text-primary-300 border border-primary-300"
          onClick={() => navigate(accounts.index)}
          dataTestId="go-to-account-btn"
        >
          Back to Account List
        </Button>
        <Button
          className="py-3 rounded-md  text-primary-300 border border-primary-300"
          onClick={() => navigate(dashboard.index)}
        >
          Back to Dashboard
        </Button>
      </div>
    </div>
  );
};

export default ConnectAccountComplete;
