import React from "react";

const ErrorStatusIcon = () => {
  return (
    <svg width="78" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M77.758 47.1c-.7 3.9-7.6 5.5-9.4 8.8-1.8 3.4.5 10.1-2.2 12.7-2.8 2.6-9.3-.1-12.8 1.6-3.4 1.6-5.4 8.4-9.2 8.9-3.7.5-7.4-5.5-11.3-6.2-3.9-.7-9.5 3.6-12.7 1.8-3.4-1.8-2.8-8.9-5.5-11.6-2.6-2.8-9.7-2.6-11.4-6-1.6-3.4 2.9-8.8 2.4-12.6-.5-3.7-6.3-7.7-5.6-11.6.7-3.9 7.6-5.5 9.4-8.8 1.8-3.4-.5-10.1 2.2-12.7 2.8-2.6 9.3.1 12.8-1.6 3.4-1.6 5.4-8.4 9.2-8.9 3.7-.5 7.4 5.5 11.3 6.2 3.9.7 9.5-3.6 12.7-1.8 3.4 1.8 2.8 8.9 5.5 11.6 2.6 2.8 9.7 2.6 11.4 6 1.6 3.4-2.9 8.8-2.4 12.6.5 3.8 6.3 7.8 5.6 11.6Z"
        fill="#351794"
      />
      <path
        d="M70.659 45.8c-.6 3.2-6.2 4.5-7.7 7.2-1.5 2.8.4 8.2-1.8 10.3-2.3 2.2-7.6 0-10.4 1.3-2.8 1.4-4.4 7-7.5 7.4-3 .4-6-4.5-9.2-5.1-3.2-.6-7.7 2.9-10.4 1.5-2.8-1.5-2.3-7.3-4.5-9.5-2.2-2.3-7.9-2.1-9.3-4.9-1.3-2.8 2.4-7.2 2-10.3-.4-3-5.2-6.3-4.6-9.4.6-3.2 6.2-4.5 7.7-7.2 1.5-2.8-.4-8.2 1.8-10.3 2.3-2.2 7.6 0 10.4-1.3 2.8-1.3 4.4-6.9 7.6-7.3 3-.4 6 4.5 9.2 5.1 3.2.6 7.7-2.9 10.4-1.5 2.8 1.5 2.3 7.3 4.5 9.5 2.2 2.3 7.9 2.1 9.3 4.9 1.3 2.8-2.4 7.2-2 10.3.3 2.9 5.1 6.2 4.5 9.3Z"
        fill="#5433FF"
      />
      <path
        d="m20.558 46.7 15.9-24.7c1.2-1.9 3.9-1.9 5.1 0l15.9 24.7c1.3 2-.2 4.7-2.5 4.7h-31.9c-2.4 0-3.8-2.7-2.5-4.7Z"
        fill="#A4FECB"
      />
      <path
        d="m40.018 26.373-.171 13.767h-2.2l-.185-13.767h2.556ZM37.383 44.33c0-.395.119-.729.356-1.001.246-.272.606-.409 1.08-.409.466 0 .821.137 1.067.409.255.272.382.606.382 1.001 0 .378-.127.703-.382.975-.246.272-.601.408-1.067.408-.474 0-.834-.136-1.08-.408a1.435 1.435 0 0 1-.356-.975Z"
        fill="#351794"
      />
    </svg>
  );
};

export default ErrorStatusIcon;
