import { Transaction as TransactionDto } from "@nestcoinco/onboard-api-gateway-api-client";
import BackButton from "modules/common/components/Button/BackButton";
import { NavLink } from "react-router-dom";
import dateService from "services/dateService";
import { getValueFromMetadata } from "utils/common";


type TransactionPropsType = {
  transaction: TransactionDto;
  setTransaction: (item: any) => void
}

function Transaction({ transaction, setTransaction }: TransactionPropsType) {
  return (
    <>
      <div className="max-w-[700px] mx-auto pb-8">
        <div className="mb-4">
          <BackButton handleClick={() => setTransaction(null)}/>
        </div>
        <div className="border border-gray-100 rounded-md p-6 bg-white">
          <h1 className="font-semibold text-xl">Transaction Details</h1>
          <ul>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">Date</span>
              {dateService.localizeFormat(transaction?.createdAt!)}
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Amount
              </span>
              {transaction?.assetCode} {transaction?.amount}
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Description
              </span>
              Traded {getValueFromMetadata('localCurrency', transaction?.metadata!)} for {transaction?.assetCode}
            </li>
            <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">Fee</span>
              {transaction?.assetCode} {transaction?.feeAmount}
            </li>
          </ul>


          {getValueFromMetadata('rate', transaction?.metadata!) ? (
            <>
              <h2 className="font-semibold text-lg mt-6">Exchange Details</h2>
              <ul>
                <li className="flex gap-6 py-2">
                  <span className="font-semibold basis-40 inline-block">Rate</span>
                  {getValueFromMetadata('localCurrency', transaction?.metadata!)} {getValueFromMetadata('rate', transaction?.metadata!)}
                </li>
                <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Total Amount
              </span>
                  {getValueFromMetadata('localCurrency', transaction?.metadata!)} {Number(getValueFromMetadata('rate', transaction?.metadata!)) * transaction?.amount!}
                </li>
                <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Account
              </span>
                  {getValueFromMetadata('accountName', transaction?.metadata!)}
                </li>
                <li className="text-center py-2 text-primary underline underline-offset-4">
                  <NavLink to={`/orders/${transaction?.reference}`}>View order details</NavLink>
                </li>
              </ul>
            </>
          ) : null}


          {
            transaction.blockchainInfo ? (<>
                <h2 className="font-semibold text-lg mt-6">Blockchain Details</h2>
                <ul>
                  <li className="flex gap-6 py-2">
                    <span className="font-semibold basis-40 inline-block">Token</span>
                    {transaction?.blockchainInfo?.tokenCode}
                  </li>
                  <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Amount
              </span>
                    {transaction?.amount}
                  </li>

                  <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Recipient Address
              </span> {transaction?.blockchainInfo?.recipient.address}
                  </li>

                  <li className="flex gap-6 py-2">
              <span className="font-semibold basis-40 inline-block">
                Network
              </span>
                    {transaction?.blockchainInfo?.networkName}
                  </li>
                  {
                    transaction?.blockchainInfo?.txExplorerUrl &&
                      <li className="text-center py-2 text-primary underline underline-offset-4">
                        <a href={transaction?.blockchainInfo?.txExplorerUrl + transaction?.blockchainInfo?.txHash}
                           target='_blank'>View on explorer</a>
                      </li>
                  }
                </ul>
              </>)
              : null
          }
        </div>
      </div>
    </>
  );
}

export default Transaction;
