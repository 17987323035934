import toastNotificationService, { ToastTypeEnum, } from "services/toastNotificationService";
import { Configuration } from '@nestcoinco/onboard-api-gateway-api-client';
import { BASE_PATH } from "@nestcoinco/onboard-api-gateway-api-client/dist/base";
import { BASE_URL } from "modules/common/api/constants";

const basePath = BASE_URL || BASE_PATH;

export const config = new Configuration({ basePath });

export type ApiClient<T> = {
  new(
    configuration?: Configuration,
    basePath?: string,
  ): T;
};

export function createClientInstance<U>(Target: ApiClient<U>) {
  return new Target({
    ...config,
    isJsonMime: () => true
  });
}

export default class APIServices {
  protected headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  protected async responseHandler(response: any) {
    const message = response.statusText || response.data;
    let hasMessage = message && !!message;

    if (response.status === 401 || response.status === 403) {
      if (response?.data?.code === 'UNAUTHENTICATED') {
        localStorage.removeItem("accessToken");
        toastNotificationService.alert(
          "Expired token. Please login",
          ToastTypeEnum.ERROR
        );

        if (typeof window !== "undefined") {
          window.location.href = "/auth/login";
        }
      }
    }

    if (hasMessage) {
      return Promise.reject(message);
    } else return Promise.reject();
  }

  protected getToken(): string {
    return localStorage.getItem("accessToken") as string;
  }
}
