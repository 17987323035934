import { routes } from "config/routes";
import React from "react";
import { Link } from "react-router-dom";
import ErrorStatusIcon from "../Icons/ErrorStatusIcon";

interface ErrorContainerProps {
  errorMessage?: string;
  errorTitle?: string;
}

const ErrorContainer = ({
  errorMessage,
  errorTitle = "Failed to fetch data",
}: ErrorContainerProps) => {
  return (
    <main>
      <div className="grid place-content-center w-full h-[650px] text-center">
        <div className="w-full flex justify-center">
          <ErrorStatusIcon />
        </div>
        <h3 className="title-1 text-center mt-3 mb-3 text-primary-main">
          {errorTitle}
        </h3>
        {errorMessage ? (
          <>
            <p className="footnote md:caption-1 text-black-100 mb-1">
              {errorMessage}
            </p>
            <Link
              to={routes.home}
              replace
              className="hover:underline text-primary-500 footnote md:caption-1"
            >
              Return to homepage
            </Link>
          </>
        ) : (
          <p className="footnote text-black-100">
            An error occured while fetching
            <br />{" "}
            <Link
              to="/ads"
              replace
              className="hover:underline text-primary-500 caption-1"
            >
              Create an Ad
            </Link>
          </p>
        )}
      </div>
    </main>
  );
};

export default ErrorContainer;
