import {
  PayoutProvider,
  ProviderSetupType,
} from "@nestcoinco/onboard-api-gateway-api-client";
import payoutService, {
  CompleteProviderAuthorizationDataType,
  InitiateProviderAuthorizationDataType,
} from "api/payoutService";
import { routes } from "config/routes";
import { LoadingButton } from "modules/common/components/Button/Button";
import React, {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toastNotificationService, {
  ToastTypeEnum,
} from "services/toastNotificationService";

const ConnectAccountWithOTP = () => {
  const { accounts } = routes;
  const navigate = useNavigate();
  const location = useLocation();
  const [provider, setProvider] = useState<PayoutProvider>();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [otp, setOtp] = useState<string>("");
  const [otpError, setOtpError] = useState({
    message: "",
    error: false,
  });
  const [accountId, setAccountId] = useState<string>("");
  const [otpDisplay, setOtpDisplay] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!location.state.provider) {
      return navigate(accounts.connect);
    }

    setProvider(location.state.provider);
  }, []);

  const checkProviderInputFields = useCallback(() => {
    if (
      provider?.setupRequirement.usernameLabel &&
      provider.setupRequirement.passwordLabel
    ) {
      return credentials.password && credentials.username;
    }
    if (provider?.setupRequirement.passwordLabel) {
      return credentials.password;
    }
    return credentials.username;
  }, [
    credentials.password,
    credentials.username,
    provider?.setupRequirement.passwordLabel,
    provider?.setupRequirement.usernameLabel,
  ]);

  const handleCredSubmit = () => {
    let payload: InitiateProviderAuthorizationDataType;

    if (!checkProviderInputFields()) {
      return;
    }

    switch (provider?.setupRequirement.setupType) {
      case ProviderSetupType.API_KEY:
        payload = {
          providerId: provider.id,
          apiKey: credentials.username,
          apiSecret: credentials.password,
        };
        break;
      case ProviderSetupType.USERNAME_PASSWORD:
        payload = {
          providerId: provider.id,
          username: credentials.username,
          password: credentials.password,
        };
        break;
      default:
        toastNotificationService.alert(
          "Provider not properly configured",
          ToastTypeEnum.WARN
        );
        return navigate(accounts.connect, { replace: true });
    }

    if (provider?.setupRequirement.requires2Fa) {
      setLoading(true);
      payoutService
        .initiateProviderAuthorization(payload)
        .then((data) => {
          if (!data.success) {
            toastNotificationService.alert(data.message!, ToastTypeEnum.ERROR);
            return navigate(accounts.connect, { replace: true });
          } else {
            // set accountId
            toastNotificationService.alert("OTP sent", ToastTypeEnum.SUCCESS);
            setAccountId(data.data?.accountId!);
            setOtpDisplay(true);
          }
        })
        .finally(() => setLoading(false));
    } else {
      completeProviderAuthorization(payload);
    }
  };

  const handleOtpSubmit = () => {
    if (!otp) {
      return;
    }
    const payload = {
      providerId: provider?.id!,
      otp,
      username: credentials.username,
      password: credentials.password,
    };
    completeProviderAuthorization(payload);
  };

  const completeProviderAuthorization = (
    payload: CompleteProviderAuthorizationDataType
  ) => {
    setLoading(true);
    return payoutService
      .completeProviderAuthorization(payload, accountId)
      .then((data) => {
        if (!data.success) {
          toastNotificationService.alert(data.message!, ToastTypeEnum.ERROR);
          return navigate(accounts.connect, { replace: true });
        } else {
          navigate(accounts.connectComplete, {
            replace: true,
            state: { providerName: provider?.name },
          });
        }
      })
      .catch((error) => {
        toastNotificationService.alert(error.message, ToastTypeEnum.ERROR);
        setOtpError({
          error: true,
          message: error.message || "Authorization failed",
        });
      })
      .finally(() => setLoading(false));
  };

  const handleCredentialChange = (e: BaseSyntheticEvent) => {
    e.preventDefault();

    const name = e.target.name;
    const value = e.target.value;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleOtpChange = (e: BaseSyntheticEvent) => {
    e.preventDefault();

    const value = e.target.value;
    setOtp(value);
  };

  return (
    <div className="mx-auto w-[500px] mt-14 bg-white rounded-[10px] p-10">
      <h1 className="text-center font-semibold pb-6">
        {!otpDisplay
          ? `Connect your ${provider?.name} account`
          : `Enter OTP sent to your phone number by ${provider?.name}`}
      </h1>
      {!otpError.error ? (
        <div className="flex flex-col gap-3 items-center">
          {!otpDisplay ? (
            <>
              {provider?.setupRequirement.usernameLabel && (
                <input
                  className="p-4 mb-6 w-full border placeholder:text-grey-500 rounded-lg"
                  placeholder={provider?.setupRequirement.usernameLabel}
                  name="username"
                  value={credentials.username}
                  onChange={handleCredentialChange}
                  data-testid="account-username-input"
                />
              )}
              {provider?.setupRequirement.passwordLabel && (
                <input
                  className="p-4 mb-6 w-full border placeholder:text-grey-500 rounded-lg"
                  placeholder={provider?.setupRequirement.passwordLabel}
                  name="password"
                  type="password"
                  value={credentials.password}
                  onChange={handleCredentialChange}
                  data-testid="account-password-input"
                />
              )}
            </>
          ) : (
            <>
              <input
                className="p-4 mb-6 w-full border placeholder:text-grey-500 rounded-lg"
                placeholder={"OTP"}
                name="otp"
                value={otp}
                onChange={handleOtpChange}
                data-testid="account-otp-input"
              />
            </>
          )}
          <LoadingButton
            loading={loading}
            className="w-full btn-primary"
            disabled={
              loading || otpDisplay ? !otp : !checkProviderInputFields()
            }
            onClick={otpDisplay ? handleOtpSubmit : handleCredSubmit}
            dataTestId="account-cred-submit"
          >
            {provider?.setupRequirement.requires2Fa && !otpDisplay
              ? "Continue"
              : "Complete setup"}
          </LoadingButton>
        </div>
      ) : (
        <div className="border p-10 flex flex-col gap-3 rounded-md items-center">
          <p>We could not verify your connection details.</p>
          <p>
            <strong>Reason:</strong> {otpError.message}
          </p>
          <p>Please try again</p>

          <p
            className="cursor-pointer underline text-primary"
            onClick={() => navigate("/accounts/connect", { replace: true })}
          >
            Back
          </p>
        </div>
      )}
    </div>
  );
};

export default ConnectAccountWithOTP;
