const LeftArrowCircle = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3709 14.5H20.6289"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0166 20.1219L8.3706 14.4999L14.0166 8.87793"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.875 1C4.46949 1 0.999993 4.4695 0.999993 14.875C0.999993 25.2805 4.46949 28.75 14.875 28.75C25.2805 28.75 28.75 25.2805 28.75 14.875C28.75 4.4695 25.2805 1 14.875 1Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LeftArrowCircle;
