export const formatPayoutMethod = (methods?: string[]) => {
  const mapping: Record<string, string> = {
    'P2P_WALLET': 'P2P'
  }
  return methods?.map((method) => {
    return mapping[method] || enumToReadable(method)
  })
}

export const enumToReadable = (value: string) => {
  const caps = value.replace(/_/g, " ")
  return caps[0]+caps.substring(1).toLowerCase()
}



