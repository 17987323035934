import React from "react";

type LoaderPropsType = {
  stroke?: string
};

const Loader = ({ stroke }: LoaderPropsType) => {
  return (
    <div>
      <svg
        className="animate-spin mr-3"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.24"
          d="M15.6569 4.34314C16.3997 5.08601 16.989 5.96793 17.391 6.93853C17.7931 7.90914 18 8.94942 18 10C18 11.0506 17.7931 12.0909 17.391 13.0615C16.989 14.0321 16.3997 14.914 15.6569 15.6569C14.914 16.3997 14.0321 16.989 13.0615 17.391C12.0909 17.7931 11.0506 18 10 18C8.94943 18 7.90914 17.7931 6.93853 17.391C5.96793 16.989 5.08601 16.3997 4.34315 15.6569C3.60028 14.914 3.011 14.0321 2.60896 13.0615C2.20693 12.0909 2 11.0506 2 10C2 8.94943 2.20693 7.90914 2.60896 6.93853C3.011 5.96793 3.60028 5.08601 4.34315 4.34315C5.08601 3.60028 5.96793 3.011 6.93853 2.60896C7.90914 2.20693 8.94943 2 10 2C11.0506 2 12.0909 2.20693 13.0615 2.60896C14.0321 3.011 14.914 3.60028 15.6569 4.34315L15.6569 4.34314Z"
          stroke={stroke || "white"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6569 4.34314C16.5871 5.27342 17.2736 6.41876 17.6555 7.67772C18.0374 8.93668 18.1029 10.2704 17.8463 11.5607C17.5896 12.8511 17.0187 14.0582 16.1841 15.0751C15.3495 16.0921 14.2769 16.8876 13.0615 17.391C11.846 17.8945 10.5251 18.0904 9.21586 17.9615C7.90659 17.8325 6.64933 17.3827 5.55544 16.6518C4.46155 15.9208 3.5648 14.9314 2.94463 13.7712C2.32446 12.6109 2 11.3156 2 10"
          stroke={stroke || "white"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default Loader;
