import SuccessCheck from "modules/common/components/Icons/SuccessCheck";
import React from "react";

const WithdrawalSuccess = () => {
  return (
    <div className="flex flex-col items-center justify-center rounded-md mx-auto w-[450px] h-[200px] mt-14 border border-gray-100 p-4">
      <SuccessCheck />
      <p className="text-lg mt-4">Your withdrawal has been completed.</p>
    </div>
  );
};

export default WithdrawalSuccess;
